import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case4_6({info}) {
    return (
      <>
         <div className="HHLL-H-4 grid gap-x-2 text-start">
        <label>
          HHLL   {toFixed(info.AN71, 0)}{' '}
        </label>
        <label>
          HLL
          {toFixed(info.AK71, 0)}{' '}
        </label>
        <label>
          NLL
          {toFixed(info.AH71, 0)}{' '}
        </label>
        <label>
          LLL
          {toFixed(info.AA71, 0)}{' '}
        </label>
        <label>
          LLLL
          {toFixed(info.X71, 0)}{' '}
        </label>
      </div>
      <div className="HHLIL-H-4 gap-x-3 grid text-start">
        <label>
          HHLIL {' '}
          {toFixed(info.T71, 0)}{' '}
        </label>
        <label>
          HLIL {' '}
          {toFixed(info.Q71, 0)}{' '}
        </label>
        <label>
          NLIL{' '}
          {toFixed(info.N71, 0)}{' '}
        </label>
        <label>
          LLIL{' '}
          {toFixed(info.G71, 0)}{' '}
        </label>
        <label>
          LLLIL{' '}
          {toFixed(info.D71, 0)}{' '}
        </label>
      </div>
      <div className="L-H-4">
        <label>L </label>
        {toFixed(info.BH75, 3)}
      </div>
      <div className="D-H-4">
        <label>D </label>
        {toFixed(info.BD75, 3)}
      </div>

      <div className="hv-H-4 text-center">
        <label>
          H<sub className="align-sub ">v</sub>
        </label>
        <br />
        {toFixed(info.AR71, 0)}
      </div>
      <div className="Hboot-H-4">
        <label>
          H <sub className="align-sub ">boot</sub>
        </label>
        {toFixed(info.AV75, 3)}
      </div>
      <div className="Dboot-H-4">
        <label>
          D <sub className="align-sub ">boot</sub>
        </label>
        {toFixed(info.AR75, 3)}
      </div>
        </>)
}

export default Case4_6