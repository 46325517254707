import {  Button, Form, Input, Typography } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle,  useRef,  useState } from 'react';
import useApiClient from '../Settings/useApiClient';
import { paymentApi } from '../API/paymentApi';
import useNotify from '../hooks/useNotify';
import { useParams } from 'react-router-dom';
import { ParsError } from '../Tools/ParsError';

const PaymentInfo = forwardRef(({ setLoading,loading }, ref) => {
  const [holderName, setHolderName] = useState('');
  const [holderMobile, setHolderMobile] = useState(null);
  const [holderEmail, setHolderEmail] = useState(null);
  const [houseName, setHouseName] = useState(null);
  const [street, setStreet] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [agree, setAgree] = useState(false);
  const [typePayment, setTypePayment] = useState()
  const [form] = Form.useForm();
  const {pid}=useParams();
  const apiClient = useApiClient();
  const f=useRef()
  useEffect(() => {
    paymentApi.load(apiClient).then((res) => {
      if (!res) return;
      setHolderName(res?.name);
      setHolderMobile(res?.mobile);
      setHolderEmail(res?.email);
      setHouseName(res?.address_name);
      setStreet(res?.address_street);
      setCity(res?.address_city);
      setCountry(res?.address_country);
      setPostcode(res?.address_postcode);
    });
  },[]);
  const { openNotification } = useNotify();
  const save = () => {
  
    setLoading(true);
    const data = {
      name: holderName,
      email: holderEmail,
      mobile: holderMobile,
      address_name: holderName,
      address_street: street,
      address_city: city,
      address_country: country,
      address_postcode: postcode,
      type_payment: typePayment,
    };

    paymentApi
      .save(apiClient,pid, data)
      .then((r) => {
          // if(typePayment=="subscribe"){
            document.location.href=r;
          // }
      })
      .catch((e) => 
      {
        openNotification("Error",ParsError(e.response),"Error")
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useImperativeHandle(ref,()=>({    
    submitForm(type) {
      setTypePayment(type)
      // form.submit();
      f.current.click();
    },
  }))
  return (
    <Form   form={form} className="grid bg-white  p-10 rounded col-span-2 " onFinish={save}>
      <h6>■ Card holder’s information</h6>
      <div className="grid grid-cols-2 p-10 gap-5 w-full ">
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">
            Card holder’s name
          </Typography.Text>
          <Input
            onChange={(e) => setHolderName(e.target.value)}
            value={holderName}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">
            Mobile number
          </Typography.Text>
          <Input
            onChange={(e) => setHolderMobile(e.target.value)}
            value={holderMobile}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            type='number'
            required
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">Email</Typography.Text>
          <Input
            onChange={(e) => setHolderEmail(e.target.value)}
            value={holderEmail}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
      </div>
      <h6>■ Billing address</h6>
      <div className="grid grid-cols-2 p-10 gap-5 ">
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">
            Building number / name
          </Typography.Text>
          <Input
            onChange={(e) => setHouseName(e.target.value)}
            value={houseName}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div></div>
        <div className="grid gap-y-2 col-span-2">
          <Typography.Text className="important   ">
            Street / road
          </Typography.Text>
          <Input
            onChange={(e) => setStreet(e.target.value)}
            value={street}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">
            Town / city
          </Typography.Text>
          <Input
            onChange={(e) => setCity(e.target.value)}
            value={city}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">Country</Typography.Text>
          <Input
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="important   ">Postcode</Typography.Text>
          <Input
            onChange={(e) => setPostcode(e.target.value)}
            value={postcode}
            className="bg-gray-50 border h-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Type..."
            required
          />
        </div>
        <div></div>
        <div className="grid">

          {/* <div className="">
            <Input
              onChange={(e) => setAgreeSave(e.target.checked)}
              id="save"
              value={agreeSave}
              type="checkbox"
              
              name="save"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
                htmlFor="save"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Save payment information into profile
            </label>
          </div> */}
          <div className="">
            <Input
              id="agree"
              type="checkbox"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
              required
              name="agree"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
            />
            <label
                htmlFor="agree"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 important"
            >
              I agree to the{' '}
              <a
                target="_blank"
                className="!text-[--color-3]"
                href="https://omnisep.co.uk/terms"
              >
                Terms of service
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                className="!text-[--color-3]"
                href="https://omnisep.co.uk/privacy"
              >
                Privacy policy
              </a>
              .
            </label>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button
          htmlType="submit"
          disabled={loading}
          ref={f}
          loading={loading}
          className=" w-auto m-auto  hidden rounded  text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-12 py-5 me-2 "
        >
          Save
        </Button>
      </div>
    </Form>
  );
});

export default PaymentInfo;
