import React, { useContext, useEffect, useState } from "react";
import { SettingContext } from "../Context/SettingsContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

const SubHeader = ({
  openSidebar,
  setOpenSidebar,
  fullWidth,
  setFullWidth,
  title,
}) => {
  // const params=useParams();
  const { setBreadcrumb, breadcrumb } = useContext(SettingContext);
  // const navigate=useNavigate();
//  const changePage=(bc)=>{
//   const _breadcrumb=[]
//   for(var i=0;i<breadcrumb.length;i++){
//     _breadcrumb.push(breadcrumb[i]);
//     if(breadcrumb[i].title==bc.title)
//       break;
//   }
//   setBreadcrumb(_breadcrumb)
//   navigate(bc.link)
//  }

  return (
    <div className="ms-4 my-1 relative z-50 items-center flex">
       <Breadcrumb />
    </div>
  );
};

export default SubHeader;
