import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Menu,
  Popover,
  Progress,
  Space,
  Spin,
  Tag,
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BellOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  ProjectOutlined,
  UserOutlined,
} from '@ant-design/icons';
import SubHeader from './SubHeader';
import AuthContext from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import useApiClient from '../Settings/useApiClient';
import { userApi } from '../API/userApi';
import { monthToName } from '../Tools/tools';

function Header({ openSidebar, setOpenSidebar, title }) {
  const { logout, profile } = useContext(AuthContext);

  const items = [
    {
      label: "Menu" ?? '...',
      key: 'SubMenu',
      icon:<MenuOutlined />,

      children: [
        {
          icon: (
            <img src="/images/menu/project.svg" className="translate-y-1" />
          ),
          key: 'projects',
          label: (
            <Link
              to="/"
              
              className="pb-3"
              rel="noopener noreferrer"
            >
              Projects
            </Link>
          ),
        },
        {
          icon: (
            <img src="/images/menu/profile.svg" className="translate-y-1" />
          ),
          key: 'Profile',
          label: (
            <Link to="/profile" >
              Profile
            </Link>
          ),
        },
        {
          icon: (
            <img src="/images/menu/billing.svg" className="translate-y-1" />
          ),
          key: 'billing',
          label: (
            <Link to="/invoices" >
              Billing
            </Link>
          ),
        },
        {
          icon: <img src="/images/menu/export.svg" className="translate-y-1" />,
          key: 'export',
          disabled:true,
          label: <Link to="/export">Export</Link>,
        },
        {
          icon: (
            <img src="/images/menu/support.svg" className="translate-y-1" />
          ),
          key: 'support',
          label: <Link to="/tickets">Support</Link>,
        },
        {
          icon: (
            <img src="/images/menu/archived.svg" className="translate-y-1" />
          ),
          key: 'archived',
          disabled:true,
          label: <Link to="/archives">Archived</Link>,
        },

        {
          icon: (
            <img src="/images/menu/logout.svg" className="translate-y-1" />
          ),
          key: 'logout',
          label: <button onClick={logout}>Logout</button>,
        },
      ],
    },
  ];
  const [current, setCurrent] = useState('mail');
  const userMenu = (e) => {
    setCurrent(e.key);
  };
  const SubscriptionStatusContent = () => {
    const [loading, setLoading] = useState(false);
    const apiClient = useApiClient();
    const [plan, setPlan] = useState('');
    const [percent, setPercent] = useState(100);
    useEffect(() => {
      setLoading(true);
      userApi
        .plan(apiClient)
        .then((res) => {
          setPlan(res);
          console.log(res);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }, []);
    const percentCheck = (value, max) => {
      return (value / max) * 100;
    };
    useEffect(() => {
      const _percent = percentCheck(plan.plan_sizing_remain, plan.plan_sizing);
      setPercent(_percent);
    }, [plan]);
    if (loading) return <Spin />;
    return (
      <div>
        <p className="font-bold text-center w-100 mb-3">Your current plan</p>
        <hr />
        <div className="grid grid-cols-3  p-5 gap-3 px-10">
          <div className="col-span-1">
            <Progress type="circle" percent={percent} strokeWidth={15} />
          </div>
          <div className="col-span-2 grid">
            <div className="font-bold ">
              {plan.plan_name ?? 'No'} Plan{' '}
              <Tag color="gold">{monthToName(plan.months) }</Tag>
            </div>
            <div className="font-bold">
              Remaining: {plan.plan_day_remain ?? 0} Days /{' '}
              {plan.plan_sizing_remain ?? 0} Sizing
            </div>
            <Link
              
              to={`/plans`}
              className="grid place-items-center h-10 rounded-sm !text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 w-auto"
            >
              See all plans
            </Link>
          </div>
        </div>
      </div>
    );
  };
  return (
    <nav className="fixed top-0 z-50 w-full  border-b border-gray-200 bg-white">
      <div className=" shadow w-100">
        <div className="container  m-auto grid  grid-flow-col gap-4 py-2 items-center">
          <div className="col-span-1 flex items-center">
            <Avatar size="large" icon={<UserOutlined />} />
            <Menu
              className="bg-transparent shadow-none border-none w-[300px]"
              onClick={userMenu}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
              icon={<ArrowDownOutlined/>}
              expandIcon={<ArrowUpOutlined/>}
            />
          </div>
          <div className="col-span-10 flex gap-4 ">
            <div className="flex items-center gap-2">
              <img src="/images/chat.svg" />
              <Link to="/tickets">
                <span>Support</span>
              </Link>
            </div>
            <div className="flex items-center gap-2" >
              <img src="/images/history2.svg" />
              <Popover content={<SubscriptionStatusContent />}  title="">
                <a role='button'>Subscription status</a>
              </Popover>
            </div>
          </div>
          <Link to="/" className="col-span-1 text-end items-start flex justify-start ">
            <img src="/images/logo.svg" /><sub>TM</sub>
          </Link>
        </div>
      </div>
      <SubHeader setOpenSidebar={setOpenSidebar} title={title} />
    </nav>
  );
}

export default Header;
