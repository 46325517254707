import Case1_1 from '../components/cases/Case1_1';
import Case6_1 from '../components/cases/Case6_1';
import Case1_2 from '../components/cases/Case1_2';
import Case2_1 from '../components/cases/Case2_1';
import Case2_2 from '../components/cases/Case2_2';
import Case3_1 from '../components/cases/Case3_1';
import Case3_2 from '../components/cases/Case3_2';
import Case3_3 from '../components/cases/Case3_3';
import Case3_4 from '../components/cases/Case3_4';
import Case3_5 from '../components/cases/Case3_5';
import Case3_6 from '../components/cases/Case3_6';
import Case3_7 from '../components/cases/Case3_7';
import Case3_8 from '../components/cases/Case3_8';
import Case4_1 from '../components/cases/Case4_1';
import Case4_2 from '../components/cases/Case4_2';
import Case4_3 from '../components/cases/Case4_3';
import Case4_4 from '../components/cases/Case4_4';
import Case4_5 from '../components/cases/Case4_5';
import Case4_6 from '../components/cases/Case4_6';
import Case4_7 from '../components/cases/Case4_7';
import Case4_8 from '../components/cases/Case4_8';
import Case5_1 from '../components/cases/Case5_1';
import Case5_8 from '../components/cases/Case5_8';
import Case5_2 from '../components/cases/Case5_2';
import Case5_3 from '../components/cases/Case5_3';
import Case5_4 from '../components/cases/Case5_4';
import Case5_5 from '../components/cases/Case5_5';
import Case5_6 from '../components/cases/Case5_6';
import Case5_7 from '../components/cases/Case5_7';
import Case6_2 from '../components/cases/Case6_2';
import Case6_3 from '../components/cases/Case6_3';
import Case6_4 from '../components/cases/Case6_4';
import Case6_5 from '../components/cases/Case6_5';
import Case6_6 from '../components/cases/Case6_6';
import Case6_7 from '../components/cases/Case6_7';
import Case6_8 from '../components/cases/Case6_8';
import Case7_1 from '../components/cases/Case7_1';
import Case7_2 from '../components/cases/Case7_2';
import Case7_3 from '../components/cases/Case7_3';
import Case7_4 from '../components/cases/Case7_4';
import Case8_1 from '../components/cases/Case8_1';
import Case8_2 from '../components/cases/Case8_2';
import Case8_3 from '../components/cases/Case8_3';
import Case8_4 from '../components/cases/Case8_4';
import Case8_5 from '../components/cases/Case8_5';
import Case8_6 from '../components/cases/Case8_6';
import Case8_7 from '../components/cases/Case8_7';
import Case8_8 from '../components/cases/Case8_8';
export   const fetchSchemaData = (info,I40,demister,deverter,coalescer) => {
  if (I40 === 1 && !demister && !deverter && !coalescer) {
    return <Case1_1 info={info} />;
  } else if (I40 === 1 && demister && !deverter && !coalescer)
    return <Case1_2 info={info} />;
  else if (I40 === 2 && !demister && !deverter && !coalescer)
    return <Case2_1 info={info} />;
  else if (I40 === 2 && demister && !deverter && !coalescer)
    return <Case2_2 info={info} />;
  else if (I40 === 3 && !demister && !deverter && !coalescer)
    return <Case3_1 info={info} />;
  else if (I40 === 3 && demister && !deverter && !coalescer)
    return <Case3_2 info={info} />;
  else if (I40 === 3 && !demister && deverter && !coalescer)
    return <Case3_3 info={info} />;
  else if (I40 === 3 && !demister && !deverter && coalescer)
    return <Case3_4 info={info} />;
  else if (I40 === 3 && !demister && deverter && coalescer)
    return <Case3_5 info={info} />;
  else if (I40 === 3 && demister && !deverter && coalescer)
    return <Case3_6 info={info} />;
  else if (I40 === 3 && demister && deverter && !coalescer)
    return <Case3_7 info={info} />;
  else if (I40 === 3 && demister && deverter && coalescer)
    return <Case3_8 info={info} />;
  else if (I40 === 4 && !demister && !deverter && !coalescer)
    return <Case4_1 info={info} />;
  else if (I40 === 4 && demister && !deverter && !coalescer)
    return <Case4_2 info={info} />;
  else if (I40 === 4 && !demister && deverter && !coalescer)
    return <Case4_3 info={info} />;
  else if (I40 === 4 && !demister && !deverter && coalescer)
    return <Case4_4 info={info} />;
  else if (I40 === 4 && !demister && deverter && coalescer)
    return <Case4_5 info={info} />;
  else if (I40 === 4 && demister && !deverter && coalescer)
    return <Case4_6 info={info} />;
  else if (I40 === 4 && demister && deverter && !coalescer)
    return <Case4_7 info={info} />;
  else if (I40 === 4 && demister && deverter && coalescer)
    return <Case4_8 info={info} />;
  else if (I40 === 5 && !demister && !deverter && !coalescer)
    return <Case5_1 info={info} />;
  else if (I40 === 5 && demister && !deverter && !coalescer)
    return <Case5_2 info={info} />;
  else if (I40 === 5 && !demister && deverter && !coalescer)
    return <Case5_3 info={info} />;
  else if (I40 === 5 && !demister && !deverter && coalescer)
    return <Case5_4 info={info} />;
  else if (I40 === 5 && !demister && deverter && coalescer)
    return <Case5_5 info={info} />;
  else if (I40 === 5 && demister && !deverter && coalescer)
    return <Case5_6 info={info} />;
  else if (I40 === 5 && demister && deverter && !coalescer)
    return <Case5_7 info={info} />;
  else if (I40 === 5 && demister && deverter && coalescer)
    return <Case5_8 info={info} />;
  else if (I40 === 6 && !demister && !deverter && !coalescer)
    return <Case6_1 info={info} />;
  else if (I40 === 6 && demister && !deverter && !coalescer)
    return <Case6_2 info={info} />;
  else if (I40 === 6 && !demister && deverter && !coalescer)
    return <Case6_3 info={info} />;
  else if (I40 === 6 && !demister && !deverter && coalescer)
    return <Case6_4 info={info} />;
  else if (I40 === 6 && demister && !deverter && coalescer)
    return <Case6_5 info={info} />;
  else if (I40 === 6 && !demister && deverter && coalescer)
    return <Case6_6 info={info} />;
  else if (I40 === 6 && demister && deverter && !coalescer)
    return <Case6_7 info={info} />;
  else if (I40 === 6 && demister && deverter && coalescer)
    return <Case6_8 info={info} />;
  else if (I40 === 7 && !demister && !deverter && !coalescer)
    return <Case7_1 info={info} />;
  else if (I40 === 7 && demister && !deverter && !coalescer)
    return <Case7_2 info={info} />;
  else if (I40 === 7 && demister && deverter && !coalescer)
    return <Case7_3 info={info} />;
  else if (I40 === 7 && !demister && deverter && !coalescer)
    return <Case7_4 info={info} />;
  else if (I40 === 8 && !demister && !deverter && !coalescer)
    return <Case8_1 info={info} />;
  else if (I40 === 8 && demister && !deverter && !coalescer)
    return <Case8_2 info={info} />;
  else if (I40 === 8 && !demister && deverter && !coalescer)
    return <Case8_3 info={info} />;
  else if (I40 === 8 && !demister && !deverter && coalescer)
    return <Case8_4 info={info} />;
  else if (I40 === 8 && !demister && deverter && coalescer)
    return <Case8_5 info={info} />;
  else if (I40 === 8 && demister && !deverter && coalescer)
    return <Case8_6 info={info} />;
  else if (I40 === 8 && demister && deverter && !coalescer)
    return <Case8_7 info={info} />;
  else if (I40 === 8 && demister && deverter && coalescer)
    return <Case8_8 info={info} />;
};
export const fetchSchema=(I40,demister,deverter,coalescer,path="schematics")=>{
    if (I40 === 1 && !demister && !deverter && !coalescer) {
      return '/'+path+'/Test 1 - Case 1.svg';
    } else if (I40 === 1 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 1.svg';
    else if (I40 === 2 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 2.svg';
    else if (I40 === 2 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 2.svg';
    else if (I40 === 3 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 3.svg';
    else if (I40 === 3 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 3.svg';
    else if (I40 === 3 && !demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 3.svg';
    else if (I40 === 3 && !demister && !deverter && coalescer)
      return '/'+path+'/Test 4 - Case 3.svg';
    else if (I40 === 3 && !demister && deverter && coalescer)
      return '/'+path+'/Test 5 - Case 3.svg';
    else if (I40 === 3 && demister && !deverter && coalescer)
      return '/'+path+'/Test 6 - Case 3.svg';
    else if (I40 === 3 && demister && deverter && !coalescer)
      return '/'+path+'/Test 7 - Case 3.svg';
    else if (I40 === 3 && demister && deverter && coalescer)
      return '/'+path+'/Test 8 - Case 3.svg';
    else if (I40 === 4 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 4.svg';
    else if (I40 === 4 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 4.svg';
    else if (I40 === 4 && !demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 4.svg';
    else if (I40 === 4 && demister && deverter && !coalescer)
      return '/'+path+'/Test 4 - Case 4.svg';
    else if (I40 === 4 && demister && deverter && coalescer)
      return '/'+path+'/Test 5 - Case 4.svg';
    else if (I40 === 4 && !demister && !deverter && coalescer)
      return '/'+path+'/Test 6 - Case 4.svg';
    else if (I40 === 4 && !demister && deverter && coalescer)
      return '/'+path+'/Test 7 - Case 4.svg';
    else if (I40 === 4 && demister && !deverter && coalescer)
      return '/'+path+'/Test 8 - Case 4.svg';
    else if (I40 === 5 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 5.svg';
    else if (I40 === 5 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 5.svg';
    else if (I40 === 5 && !demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 5.svg';
    else if (I40 === 5 && !demister && !deverter && coalescer)
      return '/'+path+'/Test 4 - Case 5.svg';
    else if (I40 === 5 && !demister && deverter && coalescer)
      return '/'+path+'/Test 5 - Case 5.svg';
    else if (I40 === 5 && demister && !deverter && coalescer)
      return '/'+path+'/Test 6 - Case 5.svg';
    else if (I40 === 5 && demister && deverter && !coalescer)
      return '/'+path+'/Test 7 - Case 5.svg';
    else if (I40 === 5 && demister && deverter && coalescer)
      return '/'+path+'/Test 8 - Case 5.svg';
    else if (I40 === 6 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 6.svg';
    else if (I40 === 6 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 6.svg';
    else if (I40 === 6 && !demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 6.svg';
    else if (I40 === 6 && !demister && !deverter && coalescer)
      return '/'+path+'/Test 4 - Case 6.svg';
    else if (I40 === 6 && demister && !deverter && coalescer)
      return '/'+path+'/Test 5 - Case 6.svg';
    else if (I40 === 6 && !demister && deverter && coalescer)
      return '/'+path+'/Test 6 - Case 6.svg';
    else if (I40 === 6 && demister && deverter && !coalescer)
      return '/'+path+'/Test 7 - Case 6.svg';
    else if (I40 === 6 && demister && deverter && coalescer)
      return '/'+path+'/Test 8 - Case 6.svg';
    else if (I40 === 7 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 7.svg';
    else if (I40 === 7 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 7.svg';
    else if (I40 === 7 && demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 7.svg';
    else if (I40 === 7 && demister && !deverter && coalescer)
      return '/'+path+'/Test 4 - Case 7.svg';
    else if (I40 === 8 && !demister && !deverter && !coalescer)
      return '/'+path+'/Test 1 - Case 8.svg';
    else if (I40 === 8 && demister && !deverter && !coalescer)
      return '/'+path+'/Test 2 - Case 8.svg';
    else if (I40 === 8 && !demister && deverter && !coalescer)
      return '/'+path+'/Test 3 - Case 8.svg';
    else if (I40 === 8 && !demister && !deverter && coalescer)
      return '/'+path+'/Test 4 - Case 8.svg';
    else if (I40 === 8 && !demister && deverter && coalescer)
      return '/'+path+'/Test 5 - Case 8.svg';
    else if (I40 === 8 && demister && !deverter && coalescer)
      return '/'+path+'/Test 6 - Case 8.svg';
    else if (I40 === 8 && demister && deverter && !coalescer)
      return '/'+path+'/Test 7 - Case 8.svg';
    else if (I40 === 8 && demister && deverter && coalescer)
      return '/'+path+'/Test 8 - Case 8.svg';
  
}
export const monthToName = (month) => {
  switch (month) {
    case 1:
      return 'Monthly';
    case 3:
      return 'Quarterly ';
    case 6:
      return 'Semi-annually';
    case 12:
      return 'Yearly';
    default:
      return 'Need to buy';
  }
};
