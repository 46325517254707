import { Button, Skeleton, Spin } from 'antd';
import Typography from 'antd/es/typography/Typography';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProjectContext } from '../Context/ProjectContext';
import useApiClient from '../Settings/useApiClient';
import { preProccess, sizeIt } from '../helpers/Process';
import { sizingApi } from '../API/sizingApi';
import useAuth from '../hooks/useAuth';
import { event_change_data } from '../services/events';
import useNotify from '../hooks/useNotify';
import { readData } from '../helpers/readData';
import { ParsError } from '../Tools/ParsError';

export default function LoadingPage() {
  const [estimate, setEstimate] = useState(0);
  const workerRef = useRef(null);
  const cancelTokenRef = useRef({ canceled: false });
  const { openNotification } = useNotify();

  const { sizing, setSizingLoading, sizingLoading ,setSizing} =
    useContext(ProjectContext);
  const { saveToken, token, refreshToken, units, profile } = useAuth();
  const apiClient = useApiClient();



  const saveData = async () => {
    const data = await readData();
    sizingApi.save(apiClient, sizing.id,0, data);
  };



  const preParingsizeIt = async () => {
    sizingApi.data(apiClient, sizing.id).then((res) => {
      //
      localStorage.clear();
      saveToken(token, refreshToken, units, profile);
      localStorage.setItem('BF40', 0.99);
      localStorage.setItem('AS66', 20.4);
      
      preProccess(res)
        .then(() => {
          workerRef.current = new Worker(
            new URL('/js/worker.js', window.location.href)
          );
          workerRef.current.onmessage = (e) => {
            const { action, key, value } = e.data;

            if (action === 'change_data') {
              event_change_data(key);
            } else if (action === 'setItem') {
              localStorage.setItem(key, value);
            } else if (action === 'getItem') {
              const value = localStorage.getItem(key);
              workerRef.current.postMessage({
                action: 'receivedData',
                key,
                value,
              });
            } else if (action === 'finish') {
              saveData();
              setSizingLoading(2);
              setSizing({...sizing,status:1})
              if (workerRef.current) {
                workerRef.current.terminate();
              }
            } else if (action == 'msg') {
              openNotification('Alert', key, 200);
            }
          };
          // workerRef.current.postMessage({ cancelToken: cancelTokenRef.current });
        })
      
  })  .catch((e)=>{
      if (e?.response?.status === 422) {
        openNotification('Error', ParsError(e?.response));
        setSizingLoading(-1);
      }
    });
  };
  const handleCancel = () => {
    cancelTokenRef.current.canceled = true;
    setSizingLoading(-1);
    if (workerRef.current) {
      workerRef.current.terminate();
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setEstimate(Number(localStorage.getItem('BF45')).toFixed(3));
    }, 500);
    if (sizingLoading == 1) preParingsizeIt();
    // sizeIt();
    return function cleanup() {
      clearInterval(interval);
    };
  }, [sizingLoading]);

  return (
    <>
      {sizingLoading == 1 && (
        <div className="fixed z-50 w-full h-full  translate-x-3 grid content-center justify-center items-center bg-black opacity-70">
          <Typography.Title
            level={1}
            className="!text-white text-center m-auto"
          >
            Sizing...
          </Typography.Title>
          <p className="!text-white block ">
            Sizing in progress. Please wait a moment
          </p>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <Spin size="large" />
            <span className="text-white ms-6">
              Init. Estimated D (M) :{estimate}
            </span>
          </div>
          ‌
          <Button
            onClick={handleCancel}
            icon={<img src="/images/cancel.svg" />}
            className="!text-white hover:!text-black w-24 m-auto"
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
}
