import {createContext} from "react"

export const SettingContext=createContext({
   
    fullWidth:false,
    token:'',
    openSidebar:false,
    setFullWidth:()=>{},
    setOpenSidebar:()=>{},

})