import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case2_2({info}) {
    return (
        <>
         
                
         <div className="HHLL-2-1 grid text-start gap-x-2">
            <label>HHLL{toFixed(info.AN71,0)} </label>
            <label>HLL{toFixed(info.AK71,0)} </label>
            <label>NLL {toFixed(info.AH71,0)} </label>
            <label>LLL{toFixed(info.AA71,0)} </label>
            <label>LLLL {toFixed(info.X71,0)} </label>
          </div>
          <div className="HHLIL grid text-start gap-x-2">
            <label>HHLIL{toFixed(info.T71,0)} </label>
            <label>HLIL{toFixed(info.Q71,0)} </label>
            <label>NLIL{toFixed(info.N71,0)} </label>
            <label>LLIL {toFixed(info.G71,0)} </label>
            <label>LLLIL{toFixed(info.D71,0)} </label>
          </div>
          <div className="L">
            <label>L </label>{toFixed(info.BH75,3)}
          </div>
          <div className="D">
            <label>D </label>{toFixed(info.BD75,3)}
          </div>
          <div className="hd">
            <label>Hd </label>{info.BH71}
          </div>
          <div className="hbn">
            <label>H<sub className="align-sub ">bn</sub> </label>{info.BD71}
          </div>
          <div className="ha-2">
            <label>H<sub className="align-sub ">a</sub> </label>{toFixed(info.D66,1)} ft
          </div>
          <div className="w_d-2-1">
            <label>W<sub className="align-sub ">d</sub> </label>{toFixed(info.G66,1)} in.
          </div>
        </>)
}

export default Case2_2