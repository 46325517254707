import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DesignCriteria from './DesignCriteria';
import ProcessConditions from './ProcessConditions';
import { sizingApi } from '../API/sizingApi';
import useApiClient from '../Settings/useApiClient';
import { ProjectContext } from '../Context/ProjectContext';
import { useNavigate } from 'react-router-dom';
import useNotify from '../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ArrowRightOutlined } from '@ant-design/icons';
dayjs.extend(customParseFormat);
const ProcessSizing = () => {
  const { project,  sizing, active, setActive ,updateSizing} =
    useContext(ProjectContext);
  const [unitSystem, setUnitSystem] = useState();
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(null);
  const [revision, setRevision] = useState(null);
  const { openNotification } = useNotify();

  const [checked, setChecked] = useState(null);
  const [calc_no, setCalc_no] = useState(null);
  const [prepared, setPrepared] = useState(null);
  const [date, setDate] = useState(null);
  const [date2, setDate2] = useState(null);
  // const navigate=useNavigate();
  const [seperatorTag, setSeperatorTag] = useState();
  const clientApi = useApiClient();
  useEffect(() => {
    if (sizing){
       setSeperatorTag(sizing.separator_tag);
      setUnitSystem(sizing.unit_system);
      setApproved(sizing.approved);
      setChecked(sizing.checked);
      setCalc_no(sizing.calc_no);
      if(sizing.date)
      setDate( dayjs(sizing.date, "YYYY/MM/DD"));
      setRevision(sizing.revision);
      setPrepared(sizing.prepared);
    } 
  }, [sizing]);
  // useEffect(() => {
  //   if (unitSystem) updateSizing();
  // }, [unitSystem]);
  const update = () => {
    setLoading(true);
    updateSizing({
      separator_tag: seperatorTag,
      unit_system: unitSystem,
      date: date2,
      checked: checked,
      revision: revision,
      approved: approved,
      prepared: prepared,
      calc_no: calc_no,
    }).finally(()=>  setLoading(false))
  };
  const goNext = () => {
    if (active !== 'process') setActive('process');
  };

  const onChangeDate = (date, dateString) => {
    setDate2(dateString)
    setDate(date);
  };

  return (
    <Card style={{}} type="inner" title="Design">
      <Form onFinish={update}>
        <Typography.Title level={4} className="title-1 mt-5">
          Sizing information
        </Typography.Title>
        <div className="grid grid-cols-3 gap-3">
          <div className="grid ">
            <Typography.Text className="important">
              Separator Tag:
            </Typography.Text>
            <Input
              required
              className="input-general "
              placeholder="Type..."
              value={seperatorTag}
              onChange={(e) => setSeperatorTag(e.target.value)}
            />
            <Typography.Text type="danger"></Typography.Text>
          </div>
          <div className="grid ">
            <Typography.Text className="">Revision:</Typography.Text>
            <Input
              className="input-general "
              placeholder="Type..."
              value={revision}
              onChange={(e) => setRevision(e.target.value)}
            />
            <Typography.Text type="danger"></Typography.Text>
          </div>
          <div className="grid ">
            <Typography.Text className="">Calc. No:</Typography.Text>
            <Input
              className="input-general "
              placeholder="Type..."
              value={calc_no}
              onChange={(e) => setCalc_no(e.target.value)}
            />
            <Typography.Text type="danger"></Typography.Text>
          </div>
          <div className="grid ">
            <Typography.Text className="">Approved:</Typography.Text>
            <Input
              className="input-general "
              placeholder="Type..."
              value={approved}
              onChange={(e) => setApproved(e.target.value)}
            />
            <Typography.Text type="danger"></Typography.Text>
          </div>
          <div className="grid ">
            <Typography.Text className="">Checked:</Typography.Text>
            <Input
              className="input-general "
              placeholder="Type..."
              value={checked}
              onChange={(e) => setChecked(e.target.value)}
            />
          </div>
          <div className="grid ">
            <Typography.Text className="">Prepared:</Typography.Text>
            <Input
              className="input-general "
              placeholder="Type..."
              value={prepared}
              onChange={(e) => setPrepared(e.target.value)}
            />
          </div>
          <div className="grid ">
            <Typography.Text className="">Date:</Typography.Text>
            <DatePicker
            format={"YYYY/MM/DD"}
              onChange={onChangeDate}
              value={date}
              defaultValue={date}
              className="input-general "
            />
          </div>
          <div className="grid  items-end">
            {/* <Typography.Text className=""> </Typography.Text> */}
            <Button
              htmlType="submit"
              disabled={loading} icon={<ArrowRightOutlined />}
              className=" input-general !bg-red-950 !border-red-900  "
              loading={loading}
              //  className="  rounded-none text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 py-5"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
      <div className="mb-5 pb-5">
        <Typography.Title level={4} className="title-1 mt-5">
          Design criteria
        </Typography.Title>
        <Typography.Text className="">
          The values in this section are derived from the project design
          criteria. Editing will only apply to this sizing and will not affect
          the main project.
        </Typography.Text>
        <div className="grid">
          <Typography.Text className="important mt-5">
            Unit Systems
          </Typography.Text>
          <Select
            loading={false}
            required
            className="w-80 ms-2"
            value={unitSystem}
            onChange={setUnitSystem}
            options={[
              { label: 'SI (kg, m, mm, Bar, °C)', value: 'si' },
              { label: 'Field (lb, ft, in, psi, °F)', value: 'field' },
            ]}
          />
        </div>
      </div>
      {project?.id && (
        <DesignCriteria
          project={project?.id}
          unitSystem={project.unit_system}
          sizing={sizing?.id}
          goNext={goNext}
          hasNext={true}
        />
      )}
      {/* <Divider />
      <ProcessConditions goNext={goNextStep} /> */}
    </Card>
  );
};

export default ProcessSizing;
