import React, { useContext, useEffect, useState } from 'react';
import '../../services/listeners';
import { SidebarSizing } from '../../components/SidebarSizing';
import { useParams } from 'react-router-dom';
import { sizingApi } from '../../API/sizingApi';
import ProcessSizing from '../../components/ProcessSizing';
import { ProjectApi } from '../../API/ProjectApi';
import useApiClient from '../../Settings/useApiClient';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../Context/ProjectContext';
import Configurations from '../../components/Configurations';
import LoadingPage from '../../components/LoadingPage';
import ResultSizing from '../../components/ResultSizing';
import { DesignSpecification } from '../../components/DesignSpecification';
import AnalyzeLoadingPage from '../../components/AnalyzeLoadingPage';
import useNotify from '../../hooks/useNotify';
import ProcessConditions from '../../components/ProcessConditions';
export const Sizing = () => {
  const [project, setPorject] = useState();
  const [caseStudyShow, setCaseStudyShow] = useState(false);
  const [sizing, setSizing] = useState();
  const [tipTableNumber, setTipTableNumber] = useState(null);
  const [analyzeLoading, setAnalyzeLoading] = useState(false);
  const [active, setActive] = useState('design');
  const { id, project_id } = useParams();
  const [dataInput, setDataInput] = useState(null);
  const apiClient = useApiClient();
  const [sizingLoading, setSizingLoading] = useState(false);
  const [config, setConfig] = useState();
  const navigate = useNavigate();
  const { openNotification } = useNotify();
  const clientApi = useApiClient();
  useEffect(() => {
    if (project_id)
      ProjectApi.load(apiClient, project_id)
        .then((res) => setPorject(res))
        .catch((error) => navigate('/'));
  }, [project_id]);

  useEffect(() => {
    if (!id)
      sizingApi
        .new(apiClient, project_id)
        .then((res) => {
          setSizing(res);
        })
        .catch((error) => console.error('Error:', error));
  }, [id]);
  useEffect(() => {
    if (id)
      sizingApi
        .load(apiClient, id)
        .then((res) => {
          setSizing(res);
        })
        .catch((error) => console.error('Error:', error));
  }, []);
  useEffect(() => {
    if (sizing)
      navigate({
        pathname: `/sizing/${project_id}/${sizing.id}`,
      });
  }, [sizing]);
  const updateSizing = async (data) => {
    await sizingApi
      .update(clientApi, sizing.id, data)
      .then((res) => {
        setSizing(res);
        openNotification(
          'Success',
          'Sizing Information saved successfully.',
          200
        );
      })
      .catch((e) => {});

    // .finally(() => setLoading(false));
  };

  return (
    <ProjectContext.Provider
      value={{
        setPorject,
        project,
        sizing,
        setTipTableNumber,
        tipTableNumber,
        setSizing,
        updateSizing,
        setSizingLoading,
        sizingLoading,
        analyzeLoading,
        setAnalyzeLoading,
        active,
        setActive,
        setConfig,
        config,
        caseStudyShow,
        setCaseStudyShow,
        setDataInput,
        dataInput,
      }}
    >
      <LoadingPage />
      <AnalyzeLoadingPage />
      <div className="grid grid-cols-12 grid-flow-col-dense mt-14 top-20 relative gap-3 ">
        <div className="col-span-3 ">
          <SidebarSizing project={project} />
        </div>
        <div className="col-span-6 mb-5">
          {active == 'design' && <ProcessSizing />}
          {active == 'process' && <ProcessConditions />}
          {(active == 'general' || active == 'type') && <Configurations />}
          {(active == 'Design_specifications' ||
            active == 'L/D' ||
            active == 'Preferred') && <DesignSpecification />}
        </div>

        <div className="col-span-3">
          <ResultSizing />
        </div>
      </div>
    </ProjectContext.Provider>
  );
};
