import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useApiClient from '../Settings/useApiClient';
import TextArea from 'antd/es/input/TextArea';
import DesignCriteria from '../components/DesignCriteria';
import { ProjectApi } from '../API/ProjectApi';
import NotificationContext from '../Context/NotificationContext';
import { ParsError } from '../Tools/ParsError';
import useNotify from '../hooks/useNotify';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
const ModalNewProject = ({ modalOpen, setModalOpen, project_id }) => {
  const [unitSystem, setUnitSystem] = useState('si');
  const [updataTag, setUpdataTag] = useState(false);
  const apiClient = useApiClient();
  const { openNotification } = useNotify();
  const [loading, setLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [logo1, setLogo1] = useState(null);
  const [defaultFileList, setDefaultFileList] = useState([])
  const [defaultFileList2, setDefaultFileList2] = useState([])
  const [logo2, setLogo2] = useState(null);
  const [designCriteriaOpen, setDesignCriteriaOpen] = useState(false);
 
  const [project, setProject] = useState({
    id: '',
    name: null,
    initials: '',
    description: '',
    project_number: '',
    unit_system: 'si',
    client: '',
  });
  const [projectError, setProjectError] = useState({
    id: '',
    name: null,
    initials: '',
    description: '',
    unit_system: '',
    logo1: '',
    logo2: '',
  });
 
  const dcTag = useRef();
  const navigate = useNavigate();
  // useLayoutEffect(()=>{

  // },[project_id])
  useEffect(() => {
    if (project_id !== null) {
      setLoading(true);
      ProjectApi.load(apiClient, project_id, true) //load with creteria
        .then((res) => {
          setProject(res);
          if(res.logo1[0])
         setDefaultFileList([
            {
              uid:'1',
              name: res.logo1[0],
              status: 'done',
              url: res.logo1[1],
            }
          ]);
          if(res.logo2[0])
          setDefaultFileList2([
            {
              uid:'2',
              name: res.logo2[0],
              status: 'done',
              url: res.logo2[1],
            }
          ]);
    
          setDesignCriteriaOpen(true);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, [project_id]);
  const showErrors = (error) => {
    setProjectError({
      name: error?.name?.[0] ?? '',
      initials: error?.initials?.[0] ?? '',
      description: error?.description?.[0] ?? '',
      unit_system: error?.unit_system?.[0] ?? '',
    });
  };
  const submit = () => {
    const data = {};
    setProjectLoading(true);
    const form = new FormData();
    form.append('name', project.name);
    form.append('initials', project.initials);
    form.append('description', project.description);
    if (project_id) form.append('id', project_id);
    form.append('client ', project.client);
    form.append('project_number', project.project_number);
    form.append('logo1', logo1);
    form.append('logo2', logo2);
    form.append('unit_system', project.unit_system);
    ProjectApi.new(apiClient, form)
      .then((res) => {
        updataTag
          ? openNotification('Success', 'Project updated successfully.', 200)
          : openNotification('Success', 'Project created successfully.', 200);
        setUpdataTag(true);
        setProject({ ...project, id: res.project_id });
        setDesignCriteriaOpen(true);
        setTimeout(() => {
          dcTag.current.scrollIntoView();
        }, 500);
      })
      .catch((e) => {
        if (e?.response?.status === 422) {
          openNotification('Error', ParsError(e?.response));
          if (e?.response?.data?.message) {
            showErrors(e?.response?.data.errors);
          }
        }
      })
      .finally(() => setProjectLoading(false));
  };
  const goNext = () => {
    // document.location.href=`/sizing/${project.id}`
    navigate(`/sizing/${project.id}`);
  };
  const close=()=>{
    setModalOpen(false)
  }
  const handleRemove = async (file) => {
      if(!project_id)
        return 
      ProjectApi.removeLogo(apiClient, project_id, file.uid) //load with creteria
      .then((res) => {
        if(file.uid=='1')
        openNotification('Success', 'Project Employer logo  removed successfully.', 200)
        else if(file.uid=='2')
        openNotification('Success', 'Project Consultant engineering logo removed successfully.', 200)

        setDefaultFileList((prev) => prev.filter((item) => item.uid !== file.uid));
      }).catch((e)=>console.log(e));
  
  };
  const props = {
    onRemove: handleRemove,
    beforeUpload: (file) => {
      const isPic =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isPic) {
        openNotification('Error', `${file.name} is not a picture file`);
        return false;
      }
      setLogo1(file);
      return false;
    },
    defaultFileList: defaultFileList,
  };
  const props2 = {
    onRemove: handleRemove,
    beforeUpload: (file) => {
      const isPic =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isPic) {
        openNotification('Error', `${file.name} is not a picture file`);
        return false;
      }
      setLogo2(file);
      return false;
    },
    defaultFileList: defaultFileList2,
  };
  
  return (
    <Modal
      closeIcon={false}
      title={
        <div className="w-100 bg-gray-300 pt-2 pb-2 text-center ">
          {project_id?"Project Information":"New project"}
        </div>
      }
      footer={false}
      loading={loading}
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={1000}
      destroyOnClose={true}
    >
      <div className="p-3">
        <section className="text-gray-700">
          <div className="container  mx-auto">
            <div className="flex flex-wrap  mb-2 ">
              <div className="w-full px-4 py-2 ">
                <Form onFinish={submit}>
                  <details className="mb-4 " open>
                    <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
                      Basic information
                    </summary>

                    <div className="grid grid-flow-col grid-cols-3  gap-6 p-3">
                      <div>
                        <Typography.Text className='important'>Project name</Typography.Text>
                        <Input
                          required
                          value={project?.name}
                          onChange={(e) =>
                            setProject({ ...project, name: e.target.value })
                          }
                          className="input-general"
                          placeholder="Type..."
                        />
                        <Typography.Text type="danger">
                          {projectError?.name}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text className='important'>Project initials</Typography.Text>
                        <Input
                          value={project?.initials}
                          onChange={(e) =>
                            setProject({ ...project, initials: e.target.value })
                          }
                          required
                          className="input-general"
                          placeholder="Type..."
                        />
                        <Typography.Text type="danger">
                          {projectError?.initials}
                        </Typography.Text>
                      </div>
                      <div className="grid">
                        <Typography.Text className='important'>Unit systems</Typography.Text>
                        <Select
                          required
                          defaultValue={project.unit_system}
                          onChange={(v) =>
                            setProject({ ...project, unit_system: v })
                          }
                          // onChange={setUnitSystem}
                          options={[
                            { label: 'SI (kg, m, mm, Bar, °C)', value: 'si' },
                            {
                              label: 'Field (lb, ft, in, psi, °F)',
                              value: 'field',
                            },
                          ]}
                        />
                        <Typography.Text type="danger">
                          {projectError?.unit_system}
                        </Typography.Text>
                      </div>
                    </div>
                    <div className="grid grid-flow-col grid-cols-3  gap-6 p-3">
                      <div>
                        <Typography.Text>Project Number</Typography.Text>
                        <Input
                          value={project?.project_number}
                          onChange={(e) =>
                            setProject({
                              ...project,
                              project_number: e.target.value,
                            })
                          }
                          
                          className="input-general"
                          placeholder="Type..."
                        />
                        <Typography.Text type="danger">
                          {projectError?.project_number}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text>Employer </Typography.Text>
                        <Input
                          value={project?.client}
                          onChange={(e) =>
                            setProject({
                              ...project,
                              client: e.target.value,
                            })
                          }
                          
                          className="input-general"
                          placeholder="Type..."
                        />
                        <Typography.Text type="danger">
                          {projectError?.client}
                        </Typography.Text>
                      </div>
                    </div>
                    <div className="grid grid-flow-col grid-cols-1   p-3">
                      <div className="col-span-3">
                        <Typography.Text>Description</Typography.Text>
                        <TextArea
                          count={{ max: 300, show: true }}
                          placeholder="Type..."
                          rows={4}
                          onChange={(e) =>
                            setProject({
                              ...project,
                              description: e.target.value??"",
                            })
                          }
                          value={project?.description??""}
                          className=""
                        ></TextArea>
                      </div>
                    </div>
                    <div>
                      <Flex className="gap-2 items-start font-bold">
                        <img src="/images/urgent2.svg" /> If you want logos to
                        be included in the report, you can upload them here.
                      </Flex>
                      <p className="text-[grey] ps-8 ">
                        Recommanded resolution is 640*640 with file size
                      </p>
                      <label className="text-sm block mt-4 font-bold">
                        Employer logo
                      </label>
                      <Upload {...props} accept=".png,.jpg,.jpeg" maxCount={1}> 
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload> 
                      <label className="text-sm block mt-4 font-bold">
                        Consultant engineering logo
                      </label>
                      <Upload {...props2} accept=".png,.jpg,.jpeg" maxCount={1}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </div>
                    <div className="flex justify-center ">
                      <Button
                        loading={projectLoading}
                        className="  rounded-none mb-5 mt-2 text-white bg-gray-800 hover:bg-gray-900  font-medium  text-sm px-8 py-3 me-2 "
                        htmlType="submit"
                        disabled={
                          project.name &&
                          project.initials &&
                          project.unit_system
                            ? false
                            : true
                        }
                      >
                        {updataTag ? 'Update' : 'Next'}
                      </Button>
                    </div>
                  </details>
                </Form>
                <details className="mb-4" open={designCriteriaOpen}>
                  <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
                    Design Criteria:
                  </summary>

                  <div
                    className="relative overflow-x-auto  sm:rounded-lg p-1 py-0"
                    ref={dcTag}
                  >
                    <DesignCriteria
                      project={project_id ? project_id : project.id ?? ''}
                      unitSystem={project.unit_system}
                      goNext={goNext}
                      close={close}
                    />
                  </div>
                </details>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default ModalNewProject;
