import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname
    .split("/")
    .filter((path) => path)
    .filter((path) => path && !/^\d+$/.test(path));
  const originPaths = location.pathname.split("/").filter((path) => path);

  const changePage = (index) => {
    navigate("/", {
      state: {
        pid: originPaths[index + 1],
      },
    });
  };

  return (
    <nav aria-label="breadcrumb" className="flex items-center space-x-2 !text-xs py-3">
      <Link to="/" className="!text-blue-500 hover:underline flex items-center gap-2">
      <svg viewBox="64 64 896 896" focusable="false" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path></svg>
        Projects
      </Link>
      {paths.map((path, index) => {
        const to = `/${paths.slice(0, index + 1).join("/")}`;
        const isLast = index === paths.length - 1;

        return (
          <span key={index} className="flex items-center">
            <span className="mx-2 text-blue-700">/</span>
            {path == "sizing" ? (<>
              <span
                role="button"
                onClick={() => changePage(index)}
                className="text-blue-700  hover:underline"
              >
                {decodeURIComponent("Current Project")}
              </span>
               <span className="mx-3 text-blue-500">{" / "}</span>
               <span
               role="button"
               
               className="text-gray-700  "
             >
               {decodeURIComponent("Sizing")}
             </span>
             </>
            ) 
         : (
              <Link to={to} className="!text-blue-500 hover:underline">
                {decodeURIComponent(path)}
              </Link>
            )}
          </span>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
