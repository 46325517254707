import React from 'react'
import { toFixed } from '../../helpers/Converts'

function Case8_7({info}) {
    return (
        <>
          
             <div className="HHLL-H grid text-start">
                           <label>
                             HHLL
                             {toFixed(info.AN71, 0)}
                           </label>
                           <label>
                             HLL
                             {toFixed(info.AK71, 0)}
                           </label>
                           <label>
                             NLL
                             {toFixed(info.AH71, 0)}
                           </label>
                           <label>
                             LLL
                             {toFixed(info.AA71, 0)}
                           </label>
                           <label>
                             LLLL
                             {toFixed(info.X71, 0)}
                           </label>
                         </div>
             
                      
                    <div className="L-H">
                               <label>L </label>{toFixed(info.BH75,3)}
                             </div>
                             <div className="D-H">
                               <label>D </label>{toFixed(info.BD75,3)}
                             </div>
                         <div className="hv-H text-center">
                           <label>
                             H<sub className="align-sub ">v</sub>
                           </label>
                           <br />
                           {toFixed(info.AR71, 0)}
                         </div>
        </>)
}

export default Case8_7