import { Table } from 'antd';
import React from 'react';

const columns = [
  {
    title: 'System',
    dataIndex: 'System',
    key: 'System',
  },
  {
    title: 'µm',
    dataIndex: 'µm',
    key: 'µm',
  },
];

const Table5 = ({params}) => {
  const dataSource = [
    {
      key: '1',
      System: 'Water - Oil',
      µm:  (3170 * Math.pow(params.k29 / 1000, 0.2253)).toFixed(2),
    },
    {
      key: '2',
      System: 'Oil - Gas',
      µm: '3.0 - 4.0',
    },
    {
      key: '3',
      System: 'Water - Gas',
      µm: '4.0 - 6.0',
    },
  ];
  
  return (
    <>
      <Table size='small'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        title={() => (
          <strong className="text-center m-auto block text-xs">
            Table 5 - Particle Dia. Calculator
          </strong>
        )}
      />
    </>
  );
};

export default Table5;
