import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Dropdown, Input, Modal, Popconfirm, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sizingApi } from '../API/sizingApi';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { ParsError } from '../Tools/ParsError';
const { confirm } = Modal;

const items = [
  {
    label: 'Base on',
    key: 'base_on',
  },
  // {
  //   label: 'Export',
  //   key: 'export',
  // },
  {
    type: 'divider',
  },
  {
    label: 'Add label',
    key: 'label',
  },
];
const SizingCard = ({ sizing }) => {
  const apiClient = useApiClient();
  const { openNotification } = useNotify();
  const navigate = useNavigate();
  const [label, setLabel] = useState("")
  const [select, setSelect] = useState("#87AB74")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    if( label.trim()==""){
      openNotification('Error', "The label title is needed!");
      return
    }
    sizingApi.addLabel(apiClient,sizing.id,{color:select,label:label})
    .then(
      (res) =>{
      openNotification(
        'Success',
        'Label tag saved successfully.',
        200
      ) 
      setTimeout(()=>{
        document.location.reload();
      },1000)
      setIsModalOpen(false);
      }
    )
    .catch((e) => {
      if (e?.response?.status === 422)
      openNotification('Error', ParsError(e?.response));
  
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const edit = () => {
    navigate(`/sizing/${sizing.project_id}/${sizing.id}`);
  };
  const deleteSizing = () => {
    sizingApi.delete(apiClient,sizing.id)
    .then(
      (res) =>document.location.reload()
    )
    .catch((e) => {
      if (e?.response?.status === 422)
          openNotification('Error', ParsError(e?.response));
      });
  };
  const baseOn = (sid) => {
    sizingApi
      .baseOn(apiClient, sid)
      .then(
        (res) =>
          (document.location.href = `/sizing/${sizing.project_id}/${res.id}`)
      )
      .catch((e) => {
      if (e?.response?.status === 422)
          openNotification('Error', ParsError(e?.response));
      });
  };
  
  const addLabel = () => {
    setIsModalOpen(true);
   
  };
  const onClickMenuSizing = ({ key }, r) => {
    switch (key) {
      case 'base_on':
        baseOn(r);
        break;
      case 'label':
        addLabel(r);
        break;
    }
  };
  const sizingSchema = (config) => {
    switch (config) {
      case 1:
        return '/images/schematics/1.svg';
      case 2:
        return '/images/schematics/2.svg';
      case 3:
        return '/images/schematics/3.svg';
      case 4:
        return '/images/schematics/4.svg';
      case 5:
        return '/images/schematics/5.svg';
      case 6:
        return '/images/schematics/6.svg';
      case 7:
        return '/images/schematics/7.svg';
        case 8:
          return '/images/schematics/8.svg';
      default:
        return '/images/schematics/1.svg';
    }
  };
  const sizingStatus = () => {
    switch (sizing.status) {
      case 0:
        return (
          <img className="absolute start-0 top-0 p-1" src="/images/tag-1.svg" />
        );
      case 1:
        return (
          <img className="absolute start-0 top-0 p-1" src="/images/tag-2.svg" />
        );
      case 2:
        return (
          <img className="absolute start-0 top-0 p-1" src="/images/tag-3.svg" />
        );
      default:
        return null;
    }
  };
  const removeLabel=()=>{
    sizingApi
    .removeLabel(apiClient, sizing.id)
    .then(
      (res) =>
        (document.location.href = `/`)
    )
    .catch((e) => {
    if (e?.response?.status === 422)
        openNotification('Error', ParsError(e?.response));
    });
  }
  return (
    <Badge.Ribbon  text={<span className='flex gap-2 items-center'> <CloseCircleFilled role='button' onClick={removeLabel} className='hover:text-red-400'/>{sizing.label}</span>} style={{display:sizing.label?'block':'none'}} color={sizing.color} showZero={false}>
      <Card
        hoverable
        className="text-start custom-card w-100"
        actions={[
          <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Delete the task"
            description="Are you sure to delete this sizing?"
            onConfirm={deleteSizing}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined key="delete" style={{ color: 'red' }} size={''} />
          </Popconfirm>,
          <EditOutlined key="edit" onClick={edit} />,
          <Dropdown
            menu={{ items, onClick: (e) => onClickMenuSizing(e, sizing.id) }}
            trigger={['click']}
            className="border-0"
          >
            <Button
              icon={<MoreOutlined style={{ fontSize: 20 }} />}
              className="menu-button"
            />
          </Dropdown>,
        ]}
        cover={
          <div className="!flex w-100 p-5 bg-gray-200 border-4 border-white  justify-center   relative ">
            <img
              className="w-3/4 h-32  "
              alt="example"
              src={sizingSchema(sizing?.configuration?.configuration_id)}
            />
            {sizingStatus()}
          </div>
        }
      >
        <Typography.Text className='block'>
          Separator Tag:<strong> {sizing?.separator_tag}</strong>
        </Typography.Text>
        <Typography.Text className='block'>
          ID:<strong> {sizing?.id}</strong>
        </Typography.Text>
        <Typography.Text className='block'>
          Project:<strong> {sizing?.project?.initials}</strong>
        </Typography.Text>
        <small>Last Edit: {sizing?.updated}</small>
      </Card>
      <Modal title="Label Title" open={isModalOpen} onOk={handleOk}   onCancel={handleCancel} width={300}>
      <Input required  placeholder="Type..."  className='rounded' value={label} onChange={e=>setLabel(e.target.value)} />
      <div className="font-semibold text-start text-base mt-3 w-full mb-3">Label Color</div>
      <div className="grid grid-cols-5 justify-center items-center gap-4 mb-4">
     
        <button onClick={()=>setSelect("#87AB74")}   className={`w-10 h-10 rounded-full bg-[#87AB74] ${select=="#87AB74"?"border-2 border-black":null}`}></button>
        
        <button  onClick={()=>setSelect("#F89574")}  className={`w-10 h-10 rounded-full bg-[#F89574] ${select=="#F89574"?"border-2 border-black":null}`}></button>
        <button  onClick={()=>setSelect("#234E70")}  className={`w-10 h-10 rounded-full bg-[#234E70] ${select=="#234E70"?"border-2 border-black":null}`}></button>
        <button  onClick={()=>setSelect("#C89843")}  className={`w-10 h-10 rounded-full bg-[#C89843] ${select=="#C89843"?"border-2 border-black":null}`}></button>
        <button  onClick={()=>setSelect("#70699E")}  className={`w-10 h-10 rounded-full bg-[#70699E] ${select=="#70699E"?"border-2 border-black":null}`}></button>
      </div>
      </Modal>
    </Badge.Ribbon>
  );
};

export default SizingCard;
