import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { UnitSelect } from './UnitSelect';
import { Button, Checkbox, Form, Input, Tooltip, Typography } from 'antd';

import { ProjectContext } from '../Context/ProjectContext';
import { typeSpecificApi } from '../API/typeSpecifiApi';
import useApiClient from '../Settings/useApiClient';
import useNotify from '../hooks/useNotify';
import { InfoCircleOutlined } from '@ant-design/icons';
import { H_a,  w_d } from '../assets/consts';
import Assist from './tables/Assist';

const TypeSpecificConfiguration = forwardRef(({ setTscFinish,I40 }, ref) => {
  // const [form] = Form.useForm();
  const submitRef=useRef();
  const { sizing, config,  setTipTableNumber ,dataInput} =
    useContext(ProjectContext);
  const [result, setResult] = useState();
  const [revise, setRevise] = useState(false);
  const apiClient = useApiClient();
  const { openNotification } = useNotify();
  const [hA, setHA] = useState();
  const [hAUnit, setHAUnit] = useState();
  const [wD, setWD] = useState();
  const [c, setC] = useState();
  const [dF, setDF] = useState();
  const [hhlb, setHhlb] = useState();
  const [hhlbUnit, setHhlbUnit] = useState();
  const [hllb, setHllb] = useState();
  const [hllbUnit, setHllbUnit] = useState();
  const [hv, setHv] = useState();
  const [lll, setLll] = useState();
  const [lllUnit, setLllUnit] = useState();
  const [hll, setHll] = useState();
  const [hllUnit, setHllUnit] = useState();
  const [kValue, setKValue] = useState();
  const [kS, setKS] = useState();
  const submit = () => {
    const data = {
      H_a: hA,
      H_a_unit: hAUnit,
      W_d: wD,
      C_in: c,
      D_f: dF,
      HH_L_in_Boot: hhlb,
      HH_L_in_Boot_unit: hhlbUnit,
      HL_l_in_Boot: hllb,
      HL_l_in_Boot_unit: hllbUnit,
      H_v_ratio_of_D: hv,
      L_Liq_level: lll,
      L_Liq_level_unit: lllUnit,
      H_Liq_level_below_weir: hll,
      H_Liq_level_below_weir_unit: hllUnit,
      K_value: kValue,
      K_s: kS,
      revise: revise,
    };
    typeSpecificApi
      .save(apiClient, sizing.id, data)
      .then((res) => {
        openNotification(
          'Success',
          'Type Specific Configuration saved successfully.',
          200
        );
        setTscFinish(true);
      })
      .catch((e) => {
        setTscFinish(false);
      })
  };
  useEffect(() => {
    if (sizing)
      typeSpecificApi.load(apiClient, sizing.id).then((res) => {
        if (!res) return;
        setResult(res);
        setHA(res.H_a);
        setHAUnit(res.H_a_unit);
        setWD(res.W_d);
        setC(res.C_in);
        setDF(res.D_f);
        setHhlb(res.HH_L_in_Boot);
        setHhlbUnit(res.HH_L_in_Boot_unit);
        setHllb(res.HL_l_in_Boot);
        setHllbUnit(res.HL_l_in_Boot_unit);
        setHv(res.H_v_ratio_of_D);
        setLll(res.L_Liq_level);
        setLllUnit(res.L_Liq_level_unit);
        setHll(res.H_Liq_level_below_weir);
        setHllUnit(res.H_Liq_level_below_weir_unit);
        setKValue(res.K_value);
        setKS(res.K_s);
        setRevise(res.revise);
       
      });
  }, [sizing]);
  useImperativeHandle(ref, () => ({
    submitForm() {
      submitRef.current.click();
    },
  }));

  return (
    <Form onFinish={submit}  className="p-3">
      <div className="mb-5 ">
        <Typography.Title level={4} className="title-1 mt-5">
          Type Specific Configuration
        </Typography.Title>
      </div>
      <div className="grid grid-cols-3 gap-5 gap-y-8 ">
        <div className="grid gap-y-2">
          <Typography.Text className="important   text-xs   ">
            H<sub className="align-sub ">A</sub>
            <Tooltip title={H_a}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            onChange={(e) => setHA(e.target.value)}
            value={hA}
            disabled={[3,4,5,6, 7].includes(config)}
            addonAfter={
              <UnitSelect
                disabled={[3, 4, 5, 6, 7, 8].includes(config)}
                onUnitChange={setHAUnit}
                type={'Length'}
                defaultUnitValue={result?.h_a_unit?.unit_name ?? 'ft'}
              />
            }
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2 ">
          <Typography.Text className={`  text-xs  ${[2].includes(config)?"important" :""}  `}>
            W<sub className="align-sub ">D</sub>
            <Tooltip title={w_d}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            disabled={[1, 3, 4, 5, 6, 7, 8].includes(config)}
            required={[2].includes(config)}
            onChange={(e) => setWD(e.target.value)}
            value={wD}
            addonAfter="in"
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className="   text-xs  ">
            C
            <Tooltip title={'Nozzle sizing constant. Refer to Table 4.'}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            classNames={''}
            onChange={(e) => setC(e.target.value)}
            value={c}
            addonAfter="in"
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
        </div>

        <div className="grid gap-y-2  relative">
          <Typography.Text className="important  text-xs   ">
            d<sub className="align-sub ">F</sub>
            <Tooltip title={"Enter Feed Nozzle (dF) size. Refer to C to calculate a suggestion"}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            classNames={'input-general'}
            onChange={(e) => setDF(e.target.value)}
            value={dF}
            addonAfter="in"
            className="input-addon-general focus:border-none focus:outline-none"
          />
            <sub className="absolute -bottom-3">  The minimum suggested dF =    <Assist I40={I40} c={c} /></sub>
        
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className={` text-xs  ${[4].includes(config)?"important":""}`}>
            Height of Heavy Liq. in Boot
            <Tooltip
              title={
                'For Horizontal Separators with Boot Minimum 0.5 ft is recommended.'
              }
            >
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            disabled={[1, 2, 3, 5, 6, 7, 8].includes(config)}
            required={[4].includes(config)}
            onChange={(e) => setHhlb(e.target.value)}
            value={hhlb}
            addonAfter={
              <UnitSelect
                onUnitChange={setHhlbUnit}
                type={'Length'}
                defaultUnitValue={
                  result?.h_h__l_in__boot_unit?.unit_name ?? 'ft'
                }
              />
            }
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className={` text-xs  ${[4].includes(config)?"important":""}`}>
            Height of Light Liq. in Boot
            <Tooltip title={'For Horizontal Separators with Boot.'}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            onChange={(e) => setHllb(e.target.value)}
            value={hllb}
            disabled={[1, 2, 3, 5, 6, 7, 8].includes(config)}
            required={[4].includes(config)}
            addonAfter={
              <UnitSelect
                onUnitChange={setHllbUnit}
                type={'Length'}
                defaultUnitValue={
                  result?.h_l_l_in__boot_unit?.unit_name ?? 'ft'
                }
              />
            }
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2 relative">
          <Typography.Text className={` text-xs  ${![1, 2, 7].includes(config)?"important":""}`}>
            H<sub className="align-sub ">v</sub>
            <Tooltip title={'For Horizontal Separators'}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            onChange={(e) => setHv(e.target.value)}
            value={hv}
            disabled={[1, 2, 7].includes(config)}
            required={![1, 2, 7].includes(config)}
            // type="number"
            className="input-general focus:border-none focus:outline-none"
          />
          <sub className="absolute -bottom-3">ratio of diameter (D)</sub>
        </div>
        <div className="grid gap-y-2 relative">
          <Typography.Text className={` text-xs  ${[5,6].includes(config)?"important":""}`}>
            Light. Liq. level
            <Tooltip title={'For Horizontal Separators with Weir/Buckt'}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            disabled={[1, 2, 3, 4, 7, 8].includes(config)}
            required={[5,6].includes(config)}
            onChange={(e) => setLll(e.target.value)}
            value={lll}
            addonAfter={
              <UnitSelect
                onUnitChange={setLllUnit}
                type={'Length'}
                defaultUnitValue={result?.l__liq_level_unit?.unit_name ?? 'mm'}
              />
            }
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
          <sub className="absolute -bottom-3">Below weir/Bucket</sub>
        </div>
        <div className="grid gap-y-2 relative">
          <Typography.Text className={`  text-xs  ${[5,6].includes(config)?"important":""}`}>
            Heavy. Liq. level
            <Tooltip title={'For Horizontal Separators with Weir & Bucket'}>
              <InfoCircleOutlined
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            onChange={(e) => setHll(e.target.value)}
            disabled={[1, 2, 3, 4, 7, 8].includes(config)}
            required={[5,6].includes(config)}
            value={hll}
            addonAfter={
              <UnitSelect
                onUnitChange={setHllUnit}
                
                type={'Length'}
                defaultUnitValue={
                  result?.h__liq_level_below_weir_unit?.unit_name ?? 'mm'
                }
              />
            }
            type="number"
            className="input-addon-general focus:border-none focus:outline-none"
          />
          <sub className="absolute -bottom-3">Below weir</sub>
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className=" text-xs  important   ">
            K Value (Liq. in Gas)
            <Tooltip title={'Refer to table 1'}>
              <InfoCircleOutlined
                onMouseOver={() => setTipTableNumber({ id: 1, params: {} })}
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            classNames={''}
            required
            onChange={(e) => setKValue(e.target.value)}
            value={kValue}
            type="number"
            className="input-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="grid gap-y-2">
          <Typography.Text className={` text-xs  ${![7, 8].includes(config)?"important":""}`}>
            K (Light.Liq. Heavy.Liq.)
            <Tooltip title={'Refer to table 3'}>
              <InfoCircleOutlined
                onMouseOver={() => setTipTableNumber({ id: 3, params: {} })}
                role="button"
                className="ms-1"
                style={{ color: '#808080' }}
              />
            </Tooltip>
          </Typography.Text>
          <Input
            disabled={[7, 8].includes(config)}
            required={![7, 8].includes(config)}
            type="number"
            onChange={(e) => setKS(e.target.value)}
            value={kS}
            className="input-general focus:border-none focus:outline-none"
          />
        </div>
        <div className="col-start-2"></div>
      </div>
      <div className="flex items-center gap-2 mb-3">
        <Checkbox
          checked={revise}
          disabled={[1, 2, 7].includes(config)}

          onChange={(e) => setRevise(e.target.checked)}
        >
          Revise the diameter if needed to allow for sufficient Hv.
        </Checkbox>
      </div>
      <Button htmlType='submit' className='hidden' ref={submitRef}>submit</Button>
    </Form>
  );
});

export default TypeSpecificConfiguration;
