import { event_change_data } from "../services/events";
import { toFixed } from "./Converts";
import {
  atot,
  H_DtoA_AT,
  vol_hor_L,
  vol_hor,
  vol_hor_dish,
  A_ATtoh_D,
  ceiling,
  ifError,
  round,
} from "./Helper";

export const LLLIL_blue = () => {
  const D50 = parseFloat(localStorage.getItem("D50"));
  const D71 = localStorage.getItem("D71");
  if (D71 == Math.round(D50)) return;
  localStorage.setItem("D71", Math.round(D50));
  event_change_data("LLLIL_blue");
};
export const LLIL_blue = () => {
  const D71 = parseFloat(localStorage.getItem("D71"));
  const G50 = parseFloat(localStorage.getItem("G50"));
  const G71 = localStorage.getItem("G71");
  let result = D71 + G50;
  if (G71 == result) return;
  localStorage.setItem("G71", Math.round(result));
  event_change_data("LLIL_blue");
};
export const Working_Vol_Heavy_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const D62 = parseFloat(localStorage.getItem("D62"));
  const Y40 = parseFloat(localStorage.getItem("Y40"));
  const AG40 = parseFloat(localStorage.getItem("AG40"));
  const AZ28 = parseFloat(localStorage.getItem("AZ28"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AW28 = parseFloat(localStorage.getItem("AW28"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const BF28 = parseFloat(localStorage.getItem("BF28"));
  const BC28 = parseFloat(localStorage.getItem("BC28"));
  const G71 = parseFloat(localStorage.getItem("G71"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const I75 = parseFloat(localStorage.getItem("I75"));
  const Q40 = parseFloat(localStorage.getItem("Q40"));

  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return Math.max(
        (D62 * (Y40 + AG40)) / 60,
        (D62 * AZ28) / 60,
        (0.25 * Math.PI * Math.pow(BD75, 2) * AW28) / 1000
      );
    } else if (I40 == 4) {
      return Math.max(((Y40 + AG40) / 60) * D62, (atot(AR75) * AW28) / 1000);
    } else if (I40 == 6) {
      return Math.max(
        (D62 * (Y40 + AG40)) / 60,
        (D62 * BF28) / 60,
        vol_hor_L(BC28 + G71, BD75, BD79) - I75
      );
    } else {
      return Math.max(
        (D62 * (Y40 + AG40)) / 60,
        (D62 * BF28) / 60,
        vol_hor(BC28 + G71, BD75, Q40) - I75
      );
    }
  };
  let result = calculate();

  const J71 = localStorage.getItem("J71");
  if (J71 == result) return;

  localStorage.setItem("J71", result);
  event_change_data("Working_Vol_Heavy_blue");
};
export const NLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const D62 = parseFloat(localStorage.getItem("D62"));
  const Y40 = parseFloat(localStorage.getItem("Y40"));
  const AZ28 = parseFloat(localStorage.getItem("AZ28"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AW28 = parseFloat(localStorage.getItem("AW28"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const BC28 = parseFloat(localStorage.getItem("BC28"));
  const G71 = parseFloat(localStorage.getItem("G71"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const I75 = parseFloat(localStorage.getItem("I75"));
  const AP66 = parseFloat(localStorage.getItem("AP66"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const D83 = parseFloat(localStorage.getItem("D83"));
  const BI28 = parseFloat(localStorage.getItem("BI28"));

  const calculate = () => {
    const PI = Math.PI;

    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 4) {
      return (
        ceiling(
          (Math.round(
            (G71 +
              Math.max(
                (AP66 / 2) * 304.8,
                (((Y40 / 60) * D62) / atot(AR75)) * 1000
              )) /
              10
          ) *
            10) ,
            50
        ) 
      );
    } else {
      let value;
      if (I40 == 1 || I40 == 2) {
        value =
          Math.max(
            (((Y40 / 60) * D62) / (0.25 * PI * Math.pow(BD75, 2))) * 1000,
            AW28 / 2,
            ((D62 * AZ28) / 60 / 2 / atot(BD75)) * 1000
          ) + G71;
      } else if (I40 == 5) {
        value = Math.max(
          A_ATtoh_D((Y40 / 60) * D62 + I75, atot(BD75) * AR79) * BD75 * 1000,
          G71 + BC28 / 2,
          G71 + BI28 / 2
        );
      } else if (I40 == 6) {
        value = Math.max(
          A_ATtoh_D((Y40 / 60) * D62 + I75, atot(BD75) * BD79) * BD75 * 1000,
          G71 + BC28 / 2,
          G71 + BI28 / 2
        );
      } else {
        value = Math.max(
          A_ATtoh_D((Y40 / 60) * D62 + I75, D83) * BD75 * 1000,
          G71 + BC28 / 2,
          G71 + BI28 / 2
        );
      }
      return ceiling(value, 50);
    }
  };
  let result = calculate();

  const N71 = localStorage.getItem("N71");
  if (N71 == result) return;
  localStorage.setItem("N71", result);
  event_change_data("NLIL");
};

export const HLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const G71 = parseFloat(localStorage.getItem("G71"));
  const J71 = parseFloat(localStorage.getItem("J71"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const I75 = parseFloat(localStorage.getItem("I75"));
  const AP66 = parseFloat(localStorage.getItem("AP66"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const D83 = parseFloat(localStorage.getItem("D83"));

  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return ceiling((J71 / atot(BD75)) * 1000, 50) + G71;
    } else if (I40 == 4) {
      return ceiling(AP66 * 304.8, 50) + G71;
    } else if (I40 == 6) {
      return ceiling(A_ATtoh_D(I75 + J71, atot(BD75) * BD79) * BD75 * 1000, 50);
    } else if (I40 == 5) {
      return ceiling(A_ATtoh_D(J71 + I75, atot(BD75) * AR79) * BD75 * 1000, 50);
    } else {
      return ceiling(A_ATtoh_D(J71 + I75, D83) * BD75 * 1000, 50);
    }
  };
  let result = calculate();

  const Q71 = localStorage.getItem("Q71");
  if (Q71 == result) return;
  localStorage.setItem("Q71", result);
  event_change_data("HLIL_blue");
};
export const HHIL_blue = () => {
  const Q71 = parseFloat(localStorage.getItem("Q71"));
  const N50 = parseFloat(localStorage.getItem("N50"));
  const T71 = localStorage.getItem("T71");
  let result = Q71 + N50;
  if (T71 == result) return;
  localStorage.setItem("T71", Math.round(result));
  event_change_data("HHIL_blue");
};
export const LLLL_blue = () => {
  const T71 = parseFloat(localStorage.getItem("T71"));
  const I40 = parseFloat(localStorage.getItem("I40"));
  const Q50 = parseFloat(localStorage.getItem("Q50"));
  const X71 = localStorage.getItem("X71");
  const calculate = () => {
    if (I40 == 1 || I40 == 2 || I40 == 3 || I40 == 7 || I40 == 8) {
      // console.log(T71, Q50);
      return T71 + Q50;
    } else if (I40 == 4 || I40 == 5 || I40 == 6) {
      return Q50;
    }
    return 0;
  };
  let result = calculate();
  if (X71 == result) return;
  localStorage.setItem("X71", Math.round(result));
  event_change_data("LLLL_blue");
};
export const LLL_blue = () => {
  const X71 = parseFloat(localStorage.getItem("X71"));
  const T50 = parseFloat(localStorage.getItem("T50"));
  const AA71 = localStorage.getItem("AA71");
  let result = X71 + T50;
  // console.log(X71, T50);
  if (AA71 == result) return;
  localStorage.setItem("AA71", Math.round(result));
  event_change_data("LLL_blue");
};

export const Working_Vol_Light_blue = () => {
  const H62 = parseFloat(localStorage.getItem("H62"));
  const I40 = parseFloat(localStorage.getItem("I40"));
  const U40 = parseFloat(localStorage.getItem("U40"));
  const AC40 = parseFloat(localStorage.getItem("AC40"));
  const AZ24 = parseFloat(localStorage.getItem("AZ24"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AW24 = parseFloat(localStorage.getItem("AW24"));
  const BF24 = parseFloat(localStorage.getItem("BF24"));
  const BC24 = parseFloat(localStorage.getItem("BC24"));
  const AA71 = parseFloat(localStorage.getItem("AA71"));
  const AV79 = parseFloat(localStorage.getItem("AV79"));
  const I79 = parseFloat(localStorage.getItem("I79"));
  const Q40 = parseFloat(localStorage.getItem("Q40"));
  const AD71 = localStorage.getItem("AD71");
  const calculate = () => {
    const PI = Math.PI;

    if (I40 == 1 || I40 == 2 || I40 == 7) {
      return Math.max(
        (H62 * (U40 + AC40)) / 60,
        (H62 * AZ24) / 60,
        (0.25 * PI * Math.pow(BD75, 2) * AW24) / 1000
      );
    } else if (I40 == 5 || I40 == 6) {
      return Math.max(
        (H62 * (U40 + AC40)) / 60,
        (H62 * BF24) / 60,
        vol_hor_L(BC24 + AA71, BD75, AV79) - I79
      );
    } else {
      return Math.max(
        (H62 * (U40 + AC40)) / 60,
        (H62 * BF24) / 60,
        vol_hor(BC24 + AA71, BD75, Q40) - I79
      );
    }
  };
  let result = calculate();
  if (AD71 == result) return;
  localStorage.setItem("AD71", result);
  event_change_data("Working_Vol_Light_blue");
};
export const NLL_blue = () => {
  const H62 = parseFloat(localStorage.getItem("H62"));
  const I40 = parseFloat(localStorage.getItem("I40"));
  const U40 = parseFloat(localStorage.getItem("U40"));
  const AZ24 = parseFloat(localStorage.getItem("AZ24"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AW24 = parseFloat(localStorage.getItem("AW24"));
  const BC24 = parseFloat(localStorage.getItem("BC24"));
  const AA71 = parseFloat(localStorage.getItem("AA71"));
  const AV79 = parseFloat(localStorage.getItem("AV79"));
  const I79 = parseFloat(localStorage.getItem("I79"));
  const BI24 = parseFloat(localStorage.getItem("BI24"));
  const D83 = parseFloat(localStorage.getItem("D83"));
  const AH71 = localStorage.getItem("AH71");
  const calculate = () => {
    const PI = Math.PI;
    let result = 0;
    if (I40 == 1 || I40 == 2 || I40 == 7) {
      result =
        Math.max(
          (((U40 / 60) * H62) / (0.25 * PI * Math.pow(BD75, 2))) * 1000,
          AW24 / 2,
          ((H62 * AZ24) / 60 / 2 / atot(BD75)) * 1000
        ) + AA71;
    } else if (I40 == 5 || I40 == 6) {
      result = Math.max(
        A_ATtoh_D((U40 / 60) * H62 + I79, atot(BD75) * AV79) * BD75 * 1000,
        AA71 + BC24 / 2,
        AA71 + BI24 / 2
      );
    } else {
      result = Math.max(
        A_ATtoh_D((U40 / 60) * H62 + I79, D83) * BD75 * 1000,
        AA71 + BC24 / 2,
        BI24 / 2
      );
    }
    return ceiling(result , 50) ;
  };
  let result = calculate();
  if (AH71 == result) return;
  localStorage.setItem("AH71", result);
  event_change_data("NLL_blue");
};
export const HLL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AA71 = parseFloat(localStorage.getItem("AA71"));
  const AV79 = parseFloat(localStorage.getItem("AV79"));
  const I79 = parseFloat(localStorage.getItem("I79"));
  const D83 = parseFloat(localStorage.getItem("D83"));
  const AD71 = parseFloat(localStorage.getItem("AD71"));
  const G66 = parseFloat(localStorage.getItem("G66"));
  const AK71 = localStorage.getItem("AK71");
  const calculate = () => {
    const PI = Math.PI;

    if (I40 == 1 || I40 == 7) {
      return ceiling(
        (AD71 / (0.25 * PI * Math.pow(BD75, 2))) * 1000 + AA71,
        50
      );
    } else if (I40 == 2) {
      return ceiling(
        (AD71 / (atot(BD75) * H_DtoA_AT(BD75 - G66 * 0.0254, BD75))) * 1000 +
          AA71,
        50
      );
    } else if (I40 == 5 || I40 == 6) {
      return ceiling(
        Math.round(
          (A_ATtoh_D(AD71 + I79, atot(BD75) * AV79) * BD75 * 1000) / 10
        ) * 10,
        50
      );
    } else {
      return ceiling(
        Math.round((A_ATtoh_D(AD71 + I79, D83) * BD75 * 1000) / 10) * 10,
        50
      );
    }
  };
  let result = calculate();
  if (AK71 == result) return;
  localStorage.setItem("AK71", result);
  event_change_data("HLL_blue");
};
export const HHLL_blue = () => {
  const AK71 = parseFloat(localStorage.getItem("AK71"));
  const AA50 = parseFloat(localStorage.getItem("AA50"));
  const AN71 = localStorage.getItem("AN71");
  let result = AK71 + AA50;
  if (AN71 == result) return;
  localStorage.setItem("AN71", Math.round(result));
  event_change_data("HHLL_blue");
};
export const h_v_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AN71 = parseFloat(localStorage.getItem("AN71"));
  const AK71 = parseFloat(localStorage.getItem("AK71"));
  const AK40 = parseFloat(localStorage.getItem("AK40"));
  const AB45 = parseFloat(localStorage.getItem("AB45"));
  const X45 = parseFloat(localStorage.getItem("X45"));
  const AR71 = localStorage.getItem("AR71");
  const calculate = () => {
    if (I40 == 1 || I40 == 2 || I40 == 7) {
      return null;
    } else {
      let result;
      if (X45 == 1) {
        if (I40 == 5 || I40 == 6) {
          result = Math.max(BD75 * AK40 * 1000, (AB45 == 1 ? 2 : 1) * 304.8);
        } else {
          result = BD75 * 1000 - AN71;
        }
      } else {
        result = BD75 * 1000 - AK71;
      }
      return ceiling(result, 5);
    }
  };
  let result = calculate();
  if (AR71 == result) return;
  localStorage.setItem("AR71", result);
  event_change_data("h_v_blue");
};
export const H_w_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR71 = parseFloat(localStorage.getItem("AR71"));
  const AV71 = localStorage.getItem("AV71");
  const calculate = () => {
    if (I40 == 5 || I40 == 6) {
      return BD75 * 1000 - AR71;
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AV71 == result) return;
  localStorage.setItem("AV71", result);
  event_change_data("H_w_blue");
};
export const H_bn_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const W66 = parseFloat(localStorage.getItem("W66"));
  const AC40 = parseFloat(localStorage.getItem("AC40"));
  const AG40 = parseFloat(localStorage.getItem("AG40"));
  const H62 = parseFloat(localStorage.getItem("H62"));
  const D62 = parseFloat(localStorage.getItem("D62"));
  const BD71 = localStorage.getItem("BD71");
  const calculate = () => {
    if (I40 == 1 || I40 == 2 || I40 == 7) {
      return (
        ceiling(
          ((0.5 * W66 +
            Math.max(
              24,
              6 + (((AC40 + AG40) / 60) * (H62 + D62)) / atot(BD75) / 0.0254
            )) *
            25.4) ,
            10
        ) 
      );
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (BD71 == result) return;
  localStorage.setItem("BD71", result);
  event_change_data("H_bn_blue");
};
export const H_d_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const W66 = parseFloat(localStorage.getItem("W66"));
  const AN71 = parseFloat(localStorage.getItem("AN71"));
  const BH75 = parseFloat(localStorage.getItem("BH75"));
  const AB45 = parseFloat(localStorage.getItem("AB45"));
  const BD71 = parseFloat(localStorage.getItem("BD71"));
  const BH71 = localStorage.getItem("BH71");
  const calculate = () => {
    // if (I40 == 1 || I40 == 2) {
    //   return ceiling((36 + 0.5 * W66 + (AB45 ? 6 : 0)) * 25.4, 10);
    // } else if (I40 == 7) {
    //   return BH75 * 1000 - BD71 - AN71;
    // } else {
    //   return '-';
    // }
    return I40 === 1 || I40 === 2 || I40 === 7
      ? BH75 * 1000 - BD71 - AN71
      : "-";
  };
  let result = calculate();
  if (BH71 == result) return;
  localStorage.setItem("BH71", result);
  event_change_data("H_bn_blue");
};
export const Shell_Vol_LLLIL_bule = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const D71 = parseFloat(localStorage.getItem("D71"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const BH79 = parseFloat(localStorage.getItem("BH79"));
  const D75 = localStorage.getItem("D75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return ((0.25 * Math.PI * D71) / 1000) * Math.pow(BD75, 2);
    } else if (I40 == 4) {
      return (atot(AR75) * D71) / 1000;
    } else if (I40 == 5) {
      return vol_hor_L(D71, BD75, AR79);
    } else if (I40 == 6) {
      return vol_hor_L(D71, BD75, BD79);
    } else {
      return vol_hor(D71, BD75, BH79);
    }
  };
  let result = calculate();
  if (D75 == result) return;
  localStorage.setItem("D75", result);
  event_change_data("Shell_Vol_LLLIL_bule");
};
export const Shell_Vol_LLIL_bule = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const BH79 = parseFloat(localStorage.getItem("BH79"));
  const G71 = parseFloat(localStorage.getItem("G71"));
  const I75 = localStorage.getItem("I75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return ((0.25 * Math.PI * G71) / 1000) * Math.pow(BD75, 2);
    } else if (I40 == 4) {
      return (atot(AR75) * G71) / 1000;
    } else if (I40 == 5) {
      return vol_hor_L(G71, BD75, AR79);
    } else if (I40 == 6) {
      return vol_hor_L(G71, BD75, BD79);
    } else {
      return vol_hor(G71, BD75, BH79);
    }
  };
  let result = calculate();
  if (I75 == result) return;
  localStorage.setItem("I75", result);
  event_change_data("Shell_Vol_LLIL_bule");
};
export const Shell_Vol_HLIL_bule = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const Q71 = parseFloat(localStorage.getItem("Q71"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const BH79 = parseFloat(localStorage.getItem("BH79"));
  const N75 = localStorage.getItem("N75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return ((0.25 * Math.PI * Q71) / 1000) * Math.pow(BD75, 2);
    } else if (I40 == 3) {
      return vol_hor(Q71, BD75, BH79);
    } else if (I40 == 5) {
      return vol_hor_L(Q71, BD75, AR79);
    } else if (I40 == 6) {
      return vol_hor_L(Q71, BD75, BD79);
    } else {
      return (atot(AR75) * Q71) / 1000;
    }
  };
  let result = calculate();
  if (N75 == result) return;
  localStorage.setItem("N75", result);
  event_change_data("Shell_Vol_HLIL_bule");
};
export const Shell_Vol_HHLIL_bule = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const T71 = parseFloat(localStorage.getItem("T71"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const BH79 = parseFloat(localStorage.getItem("BH79"));
  const S75 = localStorage.getItem("S75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return ((0.25 * Math.PI * T71) / 1000) * Math.pow(BD75, 2);
    } else if (I40 == 3) {
      return vol_hor(T71, BD75, BH79);
    } else if (I40 == 5) {
      return vol_hor_L(T71, BD75, AR79);
    } else if (I40 == 6) {
      return vol_hor_L(T71, BD75, BD79);
    } else {
      return (atot(AR75) * T71) / 1000;
    }
  };
  let result = calculate();

  if (S75 == result) return;
  if (isNaN(S75) && isNaN(result)) return;

  localStorage.setItem("S75", result);
  event_change_data("Shell_Vol_HHLIL_bule");
};
export const Vol_to_LLLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const D75 = parseFloat(localStorage.getItem("D75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR83 = parseFloat(localStorage.getItem("AR83"));
  const D71 = parseFloat(localStorage.getItem("D71"));
  const AA75 = localStorage.getItem("AA75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return D75 + vol_hor_dish(BD75, BD75, I40, AR83);
    } else if (I40 == 4) {
      return D75 + (Math.PI / 24) * Math.pow(AR75, 3);
    } else {
      return (
        D75 +
        (I40 == 5 || I40 == 6 ? 1 : 2) * vol_hor_dish(D71, BD75, I40, AR83)
      );
    }
  };
  let result = calculate();
  if (AA75 == result) return;
  if (isNaN(AA75) && isNaN(result)) return;

  localStorage.setItem("AA75", result);
  event_change_data("Vol_to_LLLIL_blue");
};
export const Vol_to_LLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const I75 = parseFloat(localStorage.getItem("I75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR83 = parseFloat(localStorage.getItem("AR83"));
  const G71 = parseFloat(localStorage.getItem("G71"));
  const AE75 = localStorage.getItem("AE75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 1 || I40 == 2) {
      return I75 + vol_hor_dish(BD75, BD75, I40, AR83);
    } else if (I40 == 4) {
      return I75 + (Math.PI / 24) * Math.pow(AR75, 3);
    } else {
      return (
        I75 +
        (I40 == 5 || I40 == 6 ? 1 : 2) * vol_hor_dish(G71, BD75, I40, AR83)
      );
    }
  };
  let result = calculate();
  if (AE75 == result) return;
  if (isNaN(AE75) && isNaN(result)) return;
  localStorage.setItem("AE75", result);
  event_change_data("Vol_to_LLIL_blue");
};
export const Vol_to_HLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const N75 = parseFloat(localStorage.getItem("N75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR83 = parseFloat(localStorage.getItem("AR83"));
  const Q71 = parseFloat(localStorage.getItem("Q71"));
  const AI75 = localStorage.getItem("AI75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 4) {
      return N75 + (Math.PI / 24) * Math.pow(AR75, 3);
    } else {
      let volHorDishValue = vol_hor_dish(BD75, BD75, I40, AR83);
      if (I40 == 1 || I40 == 2) {
        return N75 + volHorDishValue;
      } else if (I40 == 3) {
        return N75 + 2 * vol_hor_dish(Q71, BD75, I40, AR83);
      } else if (I40 == 5 || I40 == 6) {
        return N75 + vol_hor_dish(Q71, BD75, I40, AR83);
      } else {
        return N75;
      }
    }
  };
  let result = calculate();
  if (AI75 == result) return;
  if (isNaN(AI75) && isNaN(result)) return;
  localStorage.setItem("AI75", result);
  event_change_data("Vol_to_HLIL_blue");
};
export const Vol_to_HHLIL_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const S75 = parseFloat(localStorage.getItem("S75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR75 = parseFloat(localStorage.getItem("AR75"));
  const AR83 = parseFloat(localStorage.getItem("AR83"));
  const T71 = parseFloat(localStorage.getItem("T71"));
  const AM75 = localStorage.getItem("AM75");
  const calculate = () => {
    if (I40 == 7 || I40 == 8) {
      return 0;
    } else if (I40 == 4) {
      return S75 + (Math.PI / 24) * Math.pow(AR75, 3);
    } else {
      let volHorDishValue = vol_hor_dish(BD75, BD75, I40, AR83);
      if (I40 == 1 || I40 == 2) {
        return S75 + volHorDishValue;
      } else if (I40 == 3) {
        return S75 + 2 * vol_hor_dish(T71, BD75, I40, AR83);
      } else if (I40 == 5 || I40 == 6) {
        return S75 + vol_hor_dish(T71, BD75, I40, AR83);
      } else {
        return S75;
      }
    }
  };
  let result = calculate();
  if (AM75 == result) return;
  if (isNaN(AM75) && isNaN(result)) return;
  localStorage.setItem("AM75", result);
  event_change_data("Vol_to_HHLIL_blue");
};
export const D_boot_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const AS66 = parseFloat(localStorage.getItem("AS66"));

  const AR75 = localStorage.getItem("AR75");
  const calculate = () => {
    if (I40 == 4) {
      return AS66;
    } else {
      return "-";
    }
  };
  console.log("d-boot", AS66);
  let result = calculate();
  if (AR75 == result) return;
  if (isNaN(AR75) && isNaN(result)) return;
  localStorage.setItem("AR75", result);

  event_change_data("D_boot_blue");
};
export const H_boot_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const AV66 = parseFloat(localStorage.getItem("AV66"));

  const AV75 = localStorage.getItem("AV75");
  const calculate = () => {
    if (I40 == 4) {
      return Math.round((AV66 * 304.8) / 10) * 10;
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AV75 == result) return;
  if (isNaN(AV75) && isNaN(result)) return;

  localStorage.setItem("AV75", result);
  event_change_data("H_boot_blue");
};
export const D_blue = () => {
  const BH75 = parseFloat(localStorage.getItem("BH75"));
  const BH66 = parseFloat(localStorage.getItem("BH66"));
  const BD66 = parseFloat(localStorage.getItem("BD66"));
  const AZ66 = parseFloat(localStorage.getItem("AZ66"));
  const BF45 = parseFloat(localStorage.getItem("BF45"));
  const BH54 = parseFloat(localStorage.getItem("BH54"));
  const Q40 = parseFloat(localStorage.getItem("Q40"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const AV79 = parseFloat(localStorage.getItem("AV79"));
  const AZ79 = parseFloat(localStorage.getItem("AZ79"));
  const BH50 = parseFloat(localStorage.getItem("BH50"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));

  const BD75 = localStorage.getItem("BD75");
  const calculate = () => {
    let part1 = ifError(ceiling(BH66, 0.05), 0);
    let part2 = ifError(ceiling(BD66, 0.05), 0);
    let part3 = ifError(ceiling(AZ66, 0.05), 0);
    let part4 = ceiling(
      0.45 * BF45 +
        Math.max(BH66, BH54, ceiling(toFixed(BF45, 2), 0.05) * Q40, BD66, AZ66),
      0.05
    );
    let part5 = ceiling(
      0.45 * BF45 +
        Math.max(BH66, BH54, ceiling(toFixed(BF45, 2), 0.05) * Q40, AZ66),
      0.05
    );
    if (
      BH75 == part1 ||
      BH75 == part2 ||
      BH75 == part3 ||
      BH75 == part4 ||
      BH75 == part5
    ) {
      return ceiling(BH75 / Q40, 0.05);
    } else if (BH75 == ifError(ceiling(AR79 + AV79 + AZ79 + BD79, 0.05), 0)) {
      return ceiling(toFixed(BF45, 2), 0.05);
    } else {
      return toFixed(ceiling(toFixed(Math.max(BF45, BH50), 2), 0.05), 4);
    }
  };
  let result = calculate();
  if (BD75 == result) return;
  if (isNaN(BD75) && isNaN(result)) return;

  localStorage.setItem("BD75", toFixed(result, 4));
  event_change_data("D_blue");
};
export const L_blue = () => {
  const BH66 = parseFloat(localStorage.getItem("BH66"));
  const BD66 = parseFloat(localStorage.getItem("BD66"));
  const AZ66 = parseFloat(localStorage.getItem("AZ66"));
  const BF45 = parseFloat(localStorage.getItem("BF45"));
  const BH54 = parseFloat(localStorage.getItem("BH54"));
  const Q40 = parseFloat(localStorage.getItem("Q40"));
  const AR79 = parseFloat(localStorage.getItem("AR79"));
  const AV79 = parseFloat(localStorage.getItem("AV79"));
  const AZ79 = parseFloat(localStorage.getItem("AZ79"));
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD79 = parseFloat(localStorage.getItem("BD79"));
  const AH45 = parseFloat(localStorage.getItem("AH45"));

  const BH75 = localStorage.getItem("BH75");
  const calculate = () => {
    const PI = Math.PI;

    if (I40 === 1 || I40 === 2 || I40 === 7) {
      return ceiling(BH54, 0.05);
    } else if (I40 === 3 || I40 === 8) {
      let innerValue = 0;
      if (AH45 && I40 === 3) {
        innerValue = 0.45 * BF45;
      }
      let maxValue = Math.max(
        BH66,
        BH54,
        ceiling(round(BF45, 100), 0.05) * Q40,
        BD66,
        AZ66
      );
      return ceiling(innerValue + maxValue, 0.05);
    } else if (I40 === 4) {
      let innerValue = 0;
      if (AH45) {
        innerValue = 0.45 * BF45;
      }
      let maxValue = Math.max(
        BH66,
        BH54,
        ceiling(round(BF45, 100), 0.05) * Q40,
        AZ66
      );
      return ceiling(innerValue + maxValue, 0.05);
    } else if (I40 === 5) {
      return ceiling(toFixed(AR79 + AV79, 4), 0.05);
    } else if (I40 === 6) {
      console.log(AR79 , AV79 , AZ79 , BD79)
      return ceiling(AR79 + AV79 + AZ79 + BD79, 0.05);
    }
    return 0;
  };
  let result = calculate();
  if (BH75 == result) return;
  if (isNaN(BH75) && isNaN(result)) return;
  localStorage.setItem("BH75", result);
  event_change_data("L_blue");
};
export const Shell_Vol_LLLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const x71 = parseFloat(localStorage.getItem("X71"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const bh79 = parseFloat(localStorage.getItem("BH79"));
  const av79 = parseFloat(localStorage.getItem("AV79"));
  const D79 = localStorage.getItem("D79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return (0.25 * Math.PI * Math.pow(bd75, 2) * x71) / 1000;
    } else if (i40 == 3 || i40 == 4 || i40 == 8) {
      return vol_hor(x71, bd75, bh79);
    } else if (i40 == 5 || i40 == 6) {
      return vol_hor_L(x71, bd75, av79);
    }
    return 0; // Default return value
  };
  let result = calculate();
  if (D79 == result) return;
  localStorage.setItem("D79", result);
  event_change_data("Shell_Vol_LLLL_blue");
};
export const Shell_Vol_LLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const aa71 = parseFloat(localStorage.getItem("AA71"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const bh79 = parseFloat(localStorage.getItem("BH79"));
  const av79 = parseFloat(localStorage.getItem("AV79"));

  const I79 = localStorage.getItem("I79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return (0.25 * Math.PI * Math.pow(bd75, 2) * aa71) / 1000;
    } else if (i40 == 3 || i40 == 4 || i40 == 8) {
      return vol_hor(aa71, bd75, bh79);
    } else if (i40 == 5 || i40 == 6) {
      return vol_hor_L(aa71, bd75, av79);
    }
    return 0; // Default return value
  };
  let result = calculate();
  if (I79 == result) return;
  if (isNaN(I79) && isNaN(result)) return;
  localStorage.setItem("I79", result);
  event_change_data("Shell_Vol_LLL_blue");
};
export const Shell_Vol_HLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const ak71 = parseFloat(localStorage.getItem("AK71"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const bh79 = parseFloat(localStorage.getItem("BH79"));
  const av79 = parseFloat(localStorage.getItem("AV79"));

  const N79 = localStorage.getItem("N79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return (0.25 * Math.PI * Math.pow(bd75, 2) * ak71) / 1000;
    } else if (i40 == 3 || i40 == 4 || i40 == 8) {
      return vol_hor(ak71, bd75, bh79);
    } else if (i40 == 5 || i40 == 6) {
      return vol_hor_L(ak71, bd75, av79);
    }
    return 0; // Default return value
  };
  let result = calculate();
  if (N79 == result) return;
  if (isNaN(N79) && isNaN(result)) return;
  localStorage.setItem("N79", result);
  event_change_data("Shell_Vol_HLL_blue");
};
export const Shell_Vol_HHLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const an71 = parseFloat(localStorage.getItem("AN71"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const bh79 = parseFloat(localStorage.getItem("BH79"));
  const av79 = parseFloat(localStorage.getItem("AV79"));

  const S79 = localStorage.getItem("S79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return (0.25 * Math.PI * Math.pow(bd75, 2) * an71) / 1000;
    } else if (i40 == 3 || i40 == 4 || i40 == 8) {
      return vol_hor(an71, bd75, bh79);
    } else if (i40 == 5 || i40 == 6) {
      return vol_hor_L(an71, bd75, av79);
    }
    return 0; // Default return value
  };
  let result = calculate();
  if (S79 == result) return;
  if (isNaN(S79) && isNaN(result)) return;
  localStorage.setItem("S79", result);
  event_change_data("Shell_Vol_HHLL_blue");
};
export const Vol_to_LLLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const d79 = parseFloat(localStorage.getItem("D79"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const ar83 = parseFloat(localStorage.getItem("AR83"));
  const x71 = parseFloat(localStorage.getItem("X71"));

  const AA79 = localStorage.getItem("AA79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return d79 + vol_hor_dish(bd75, bd75, i40, ar83);
    } else if (i40 == 3 || i40 == 4 || i40 == 5 || i40 == 8) {
      const multiplier = i40 == 5 ? 1 : 2;
      return d79 + multiplier * vol_hor_dish(x71, bd75, i40, ar83);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AA79 == result) return;
  if (isNaN(AA79) && isNaN(result)) return;

  localStorage.setItem("AA79", result);
  event_change_data("Vol_to_LLLL_blue");
};
export const Vol_to_LLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const i79 = parseFloat(localStorage.getItem("I79"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const ar83 = parseFloat(localStorage.getItem("AR83"));
  const aa71 = parseFloat(localStorage.getItem("AA71"));

  const AE79 = localStorage.getItem("AE79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return i79 + vol_hor_dish(bd75, bd75, i40, ar83);
    } else if (i40 == 3 || i40 == 4 || i40 == 5 || i40 == 8) {
      const multiplier = i40 == 5 ? 1 : 2;
      return i79 + multiplier * vol_hor_dish(aa71, bd75, i40, ar83);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AE79 == result) return;
  if (isNaN(AE79) && isNaN(result)) return;

  localStorage.setItem("AE79", result);
  event_change_data("Vol_to_LLL_blue");
};
export const Vol_to_HLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const n79 = parseFloat(localStorage.getItem("N79"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const ar83 = parseFloat(localStorage.getItem("AR83"));
  const ak71 = parseFloat(localStorage.getItem("AK71"));

  const AI79 = localStorage.getItem("AI79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return n79 + vol_hor_dish(bd75, bd75, i40, ar83);
    } else if (i40 == 3 || i40 == 4 || i40 == 5 || i40 == 8) {
      const multiplier = i40 == 5 ? 1 : 2;
      return n79 + multiplier * vol_hor_dish(ak71, bd75, i40, ar83);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AI79 == result) return;
  if (isNaN(AI79) && isNaN(result)) return;

  localStorage.setItem("AI79", result);
  event_change_data("Vol_to_HLL_blue");
};
export const Vol_to_HHLL_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const s79 = parseFloat(localStorage.getItem("S79"));
  const bd75 = parseFloat(localStorage.getItem("BD75"));
  const ar83 = parseFloat(localStorage.getItem("AR83"));
  const an71 = parseFloat(localStorage.getItem("AN71"));

  const AM79 = localStorage.getItem("AM79");
  const calculate = () => {
    if (i40 == 1 || i40 == 2 || i40 == 7) {
      return s79 + vol_hor_dish(bd75, bd75, i40, ar83);
    } else if (i40 == 3 || i40 == 4 || i40 == 5 || i40 == 8) {
      const multiplier = i40 == 5 ? 1 : 2;
      return s79 + multiplier * vol_hor_dish(an71, bd75, i40, ar83);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AM79 == result) return;
  if (isNaN(AM79) && isNaN(result)) return;

  localStorage.setItem("AM79", result);
  event_change_data("Vol_to_HHLL_blue");
};
export const L_1_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const bd58 = parseFloat(localStorage.getItem("BD58"));

  const AR79 = localStorage.getItem("AR79");
  const calculate = () => {
    if (i40 == 5 || i40 == 6) {
      return ceiling(bd58, 0.05);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AR79 == result) return;
  if (isNaN(AR79) && isNaN(result)) return;

  localStorage.setItem("AR79", result);
  event_change_data("L_1_blue");
};
export const L_2_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const bh58 = parseFloat(localStorage.getItem("BH58"));

  const AV79 = localStorage.getItem("AV79");
  const calculate = () => {
    if (i40 == 5 || i40 == 6) {
      return ceiling(bh58, 0.05);
    } else {
      return "-";
    }
  };
  let result = calculate();
  if (AV79 == result) return;
  if (isNaN(AV79) && isNaN(result)) return;

  localStorage.setItem("AV79", result);
  event_change_data("L_2_blue");
};
export const L_3_blue = () => {
  const i40 = parseFloat(localStorage.getItem("I40"));
  const bd62 = parseFloat(localStorage.getItem("BD62"));

  const AZ79 = localStorage.getItem("AZ79");
  const calculate = () => {
    if (i40 == 6) {
      return ceiling(bd62, 0.05);
    } else {
      // return '-';
      return 0;
    }
  };
  let result = calculate();
  if (AZ79 == result) return;
  if (isNaN(AZ79) && isNaN(result)) return;

  localStorage.setItem("AZ79", result);
  event_change_data("L_3_blue");
};
export const L_4_blue = async () => {
  const i40 = await parseFloat(localStorage.getItem("I40"));
  const bh62 = await parseFloat(localStorage.getItem("BH62"));

  const BD79 = localStorage.getItem("BD79");
  const calculate = () => {
    if (i40 == 6) {
      return ceiling(bh62 , 0.05); // Equivalent to CEILING($BH$62, 0.05)
    } else {
      // return '-';
      return 0;
    }
  };
  let result = calculate();
  if (BD79 == result) return;
  if (isNaN(BD79) && isNaN(result)) return;

  localStorage.setItem("BD79", result);
  event_change_data("L_4_blue");
};
export const L_d_blue = () => {
  const BH75 = parseFloat(localStorage.getItem("BH75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));

  const BH79 = localStorage.getItem("BH79");
  const calculate = () => {
    return BH75 / BD75;
  };
  let result = calculate();
  if (BH79 == result) return;
  if (isNaN(BH79) && isNaN(result)) return;

  localStorage.setItem("BH79", result);
  event_change_data("L_d_blue");
};
export const Total_Shell_Vol_blue = () => {
  const BH75 = parseFloat(localStorage.getItem("BH75"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));

  const D83 = localStorage.getItem("D83");
  const calculate = () => {
    return 0.25 * Math.PI * Math.pow(BD75, 2) * BH75;
  };
  let result = calculate();
  if (D83 == result) return;
  if (isNaN(D83) && isNaN(result)) return;

  localStorage.setItem("D83", result);
  event_change_data("Total_Shell_Vol_blue");
};
export const Total_Vol_blue = () => {
  const I40 = parseFloat(localStorage.getItem("I40"));
  const BD75 = parseFloat(localStorage.getItem("BD75"));
  const AR83 = parseFloat(localStorage.getItem("AR83"));
  const D83 = parseFloat(localStorage.getItem("D83"));

  const AA83 = localStorage.getItem("AA83");
  const calculate = () => {
    return D83 + 2 * vol_hor_dish(BD75 * 1000, BD75, I40, AR83);
  };
  let result = calculate();
  if (AA83 == result) return;
  if (isNaN(AA83) && isNaN(result)) return;

  localStorage.setItem("AA83", result);
  event_change_data("Total_Vol_blue");
};
